/**
 * Implements User repository interface with axios
 * promise based HTTP client.
 */

import Campaign from "../../domain/Campaign";
import { CampaignRepository } from "../../domain/CampaignRepository";
import axios from "axios";
import BuyerPerson from "../../domain/BuyerPerson";
import { apiUrl } from "../../../../config";

const baseURL = `${apiUrl}campaigns/`;

export class AxiosCampaignRepository implements CampaignRepository {
  async saveBuyerPerson(buyerPerson: BuyerPerson): Promise<BuyerPerson> {
    console.log("buyerPerson", buyerPerson);
    try {
      const response = await axios.post(
        baseURL + "savebuyerperson",
        buyerPerson
      );
      console.log(response);
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      //  console.error(error);
      if (error.response) {
        console.error(error.response);
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }
  destroy(CampaignId: string): Promise<any> {
    throw new Error("Method not implemented.");
  }

  delete(CampaignId: string): Promise<Campaign> {
    throw new Error("Method not implemented.");
  }

  public async index(): Promise<Campaign[]> {
    console.log(baseURL);
    try {
      const response = await axios.get(baseURL + "index");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async save(campaign: Campaign): Promise<Campaign> {
    try {
      const response = await axios.post(baseURL + "save", campaign);
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      //  console.error(error);
      if (error.response) {
        console.error(error.response);
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async update(updatedCampaign: any): Promise<Campaign> {
    try {
      const result = await axios.patch(
        baseURL + "update/" + updatedCampaign._id,
        updatedCampaign
      );
      console.log(result);
      return Promise.resolve(result.data);
    } catch (error) {
      // console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async destroyCampaign(id: string): Promise<Campaign> {
    try {
      const response = await axios.delete(baseURL + "destroy/" + id);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getCampaignById(_id) {
    try {
      const res = await axios.get(baseURL + "details/" + _id + "/");
      return res.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  async getCampaignsPage(pageSize: number, page: number): Promise<Campaign[]> {
    console.log(baseURL);
    try {
      const response = await axios.get(
        baseURL + "index/" + pageSize + "/" + page
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }
}
