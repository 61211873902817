const initialState = [];

const showSubGenres = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SHOW_SUB_GENRES":
      return [...action.showSubGenres];
    default:
      return state;
  }
};

export default showSubGenres;
