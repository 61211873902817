// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import calenderReducer from "./calenderReducer";
import emailReducer from "./email/";
// import chatReducer from "./chatReducer";
import chatReducer from "./chat/";
import contactsReducer from "./contacts/";
import usersReducer from "./users";
import showsReducer from "./shows";
import todoReducer from "./todo";
import paymentsReducer from "./payments";
import campaignReducer from "./campaigns";
import postReducer from "./posts";
import customizer from "./customizer/";
import loginReducer from "./login/loginReducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import { sessionReducer } from "redux-react-session";
import showAddFieldsErrors from "./shows/showAddFields";
import recordingTurnsReducer from "./recordingturns";
import notificationsReducer from "./notifications/notifications";
import dashboardReducer from "./dashboard";
import employeeTurnsReducer from "./employeeTurns";
import clientReducer from "./clients";
import expenseReducer from "./expenses";

const rootReducer = combineReducers({
  calender: calenderReducer,
  emailApp: emailReducer,
  contactApp: contactsReducer,
  userApp: usersReducer,
  postApp: postReducer,
  showApp: showsReducer,
  paymentApp: paymentsReducer,
  recordingTurnsApp: recordingTurnsReducer,
  campaignsApp: campaignReducer,
  todoApp: todoReducer,
  toastr: toastrReducer, // <- Mounted at toastr.
  chatApp: chatReducer,
  customizer: customizer,
  login: loginReducer,
  session: sessionReducer,
  showAddFieldsErrors: showAddFieldsErrors,
  notificationApp: notificationsReducer,
  dashboardApp: dashboardReducer,
  employeeTurnsApp: employeeTurnsReducer,
  clientApp: clientReducer,
  expenseApp: expenseReducer,
});

export default rootReducer;
