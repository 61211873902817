import EmployeeActivity from "./EmployeeActivity";

export default class EmployeeTurn {
  _id: string;
  startTime: Date; // Attribute just for user_history
  endTime: Date;
  owner: string;
  date: Date;
  activities: EmployeeActivity[];

  constructor(
    _id: string,
    startTime: Date, // Attribute just for user_history
    endTime: Date,
    owner: string,
    date: Date,
    activities: EmployeeActivity[]
  ) {
    this._id = _id;
    this.startTime = startTime;
    this.endTime = endTime;
    this.owner = owner;
    this.date = date;
    this.activities = activities;
  }

  static create(
    _id: string,
    startTime: Date, // Attribute just for user_history
    endTime: Date,
    owner: string,
    date: Date,
    activities: EmployeeActivity[]
  ) {
    return new EmployeeTurn(_id, startTime, endTime, owner, date, activities);
  }
}
