import { expenseVisibilityFilter } from "../../actions/expenses";

const ExpenseVisibilityFilter = (
  state = expenseVisibilityFilter.SHOW_ALL,
  action
) => {
  switch (action.type) {
    case "SET_VISIBILITY_FILTER":
      return action.filter;
    default:
      return state;
  }
};

export default ExpenseVisibilityFilter;
