import DomainRecordingTurn from "../../domain/RecordingTurn";
import { RecordingTurnRepository } from "../../domain/RecordingTurnRepository";
import { UpdateRecordingTurnRequest } from "../UpdateRecordingTurnRequest";

export class RecordingTurnModifier {
  private repository: RecordingTurnRepository;
  request: UpdateRecordingTurnRequest;

  constructor(repository: RecordingTurnRepository) {
    this.repository = repository;
  }

  async run(request: UpdateRecordingTurnRequest): Promise<DomainRecordingTurn> {
    return await this.repository.update(request);
  }
}
