import { recordingTurnVisibilityFilter } from "../../actions/recordingTurns";

const RecordingTurnVisibilityFilter = (
  state = recordingTurnVisibilityFilter.SHOW_ALL,
  action
) => {
  switch (action.type) {
    case "SET_VISIBILITY_FILTER":
      return action.filter;
    default:
      return state;
  }
};

export default RecordingTurnVisibilityFilter;
