const EditPayment = (state = false, action) => {
  switch (action.type) {
    case "EDIT_PAYMENT":
      return !state ? true : false;
    case "PAYMENT_DETAILS":
      return (state = false);
    default:
      return state;
  }
};

export default EditPayment;
