import { ExpenseRepository } from "../../../core/expenses/domain/ExpenseRepository";
import { AxiosExpenseRepository } from "../../../core/expenses/infrastructure/repository/AxiosExpenseRepository";
//import { ExpenseDetailGetter } from '../../../core/expenses/application/use_cases/getExpenseDetail/ExpenseDetailtGetter'
import { toastr } from "react-redux-toastr";
import { ExpenseCreator } from "../../../core/expenses/application/use_cases/createExpense/ExpenseCreator";
import { AllExpensesGetter } from "../../../core/expenses/application/use_cases/getExpenses/AllExpensesGetter";
import { ExpenseModifier } from "../../../core/expenses/application/use_cases/modifyExpense/ExpenseModifier";
import { ExpenseDestroyer } from "../../../core/expenses/application/use_cases/deleteExpense/ExpenseDestroyer";
import { FileGetter } from "../../../core/shared/use_cases/getFile/fileGetter";
import { FileCreator } from "../../../core/shared/use_cases/createFile/fileCreator";
import { FileDeleter } from "../../../core/shared/use_cases/deletefile/fileDeleter";
import { updateRecordingTurnFc } from "../recordingTurns";

const expenseRepository: ExpenseRepository = new AxiosExpenseRepository();
const indexExpenses = new AllExpensesGetter(expenseRepository);
const createExpense = new ExpenseCreator(expenseRepository);
const expenseModifier = new ExpenseModifier(expenseRepository);
const expenseDestroyer = new ExpenseDestroyer(expenseRepository);
const fileGetter = new FileGetter(expenseRepository);
const fileCreator = new FileCreator(expenseRepository);
const fileDeleter = new FileDeleter(expenseRepository);

export const addExpense = (
  _id: string,
  amountBs: Number,
  amountUsd: Number,
  amountEuro: Number,
  amountCripto: Number,
  amountOther: Number,
  owner_id: string, // user id of owner
  recording_turn_owner: string,
  concept: string,
  receiptFiles: [],
  date: Date,
  starred: Boolean,
  deleted: Boolean,
  reconciled: Boolean,
  currency: string,
  method: string,
  expense_type: string,
  seed: any
) => {
  let newExpense = {
    _id: undefined,
    amountBs: amountBs ? Number(amountBs) : 0,
    amountUsd: amountUsd ? Number(amountUsd) : 0,
    amountEuro: amountEuro ? Number(amountEuro) : 0,
    amountCripto: amountCripto ? Number(amountCripto) : 0,
    amountOther: amountOther ? Number(amountOther) : 0,
    owner_id: owner_id ? owner_id : "",
    recording_turn_owner: recording_turn_owner ? recording_turn_owner : "",
    concept: concept ? concept : "",
    receipts: [],
    date: date ? date : new Date(Date.now()),
    starred: false,
    deleted: false,
    reconciled: false,
    currency: currency,
    method: method,
    expense_type: expense_type,
  };

  return (dispatch) => {
    return createExpense
      .run(newExpense, receiptFiles)
      .then((res) => {
        toastr.success("Se ha guardado el gasto");
        dispatch({
          type: "ADD_EXPENSE",
          _id: res._id,
          amountBs: res.amountBs,
          amountUsd: res.amountUsd,
          amountEuro: res.amountEuro,
          amountCripto: res.amountCripto,
          amountOther: res.amountOther,
          owner_id: res.owner_id,
          recording_turn_owner: res.recording_turn_owner,
          concept: res.concept,
          receipts: res.receipts,
          date: res.date,
          starred: res.starred,
          deleted: res.deleted,
          reconciled: res.reconciled,
          currency: res.currency,
          method: res.method,
          expense_type: res.expense_type,
        });

        seed();

        if (res.recording_turn_owner !== "") {
          updateRecordingTurnFc(res.recording_turn_owner, "expenses", res._id)
            .then(() =>
              dispatch({
                type: "UPDATE_RECORDING_TURN",
                _id: res.recording_turn_owner,
                field: "expenses",
                value: res._id,
              })
            )
            .catch((e) => {
              console.log(e);
            });
        }
        //window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        toastr.error("Hubo un problema");
        dispatch({
          type: "ADD_EXPENSE",
          _id: _id ? _id : "",
          amountBs: amountBs ? amountBs : 0,
          amountUsd: amountUsd ? amountUsd : 0,
          amountEuro: amountEuro ? amountEuro : 0,
          amountCripto: amountCripto ? amountCripto : 0,
          amountOther: amountOther ? amountOther : 0,
          owner_id: owner_id ? owner_id : "",
          recording_turn_owner: recording_turn_owner
            ? recording_turn_owner
            : "",
          concept: concept ? concept : "",
          receipts: [],
          date: new Date(Date.now()),
          starred: false,
          approved: false,
          deleted: false,
          reconciled: false,
          currency: "",
          method: "",
          expense_type: "",
        });
      });
  };
};

export const updateExpense = (_id, field, value, selectedExpense, files) => {
  const updatedExpense = {
    _id: _id,
    amountBs: field === "amountBs" ? value : selectedExpense.amountBs,
    amountUsd: field === "amountUsd" ? value : selectedExpense.amountUsd,
    amountEuro: field === "amountEuro" ? value : selectedExpense.amountEuro,
    amountCripto:
      field === "amountCripto" ? value : selectedExpense.amountCripto,
    amountOther: field === "amountOther" ? value : selectedExpense.amountOther,
    owner_id: field === "owner_id" ? value : selectedExpense.owner_id,
    date: field === "date" ? value : selectedExpense.date,
    recording_turn_owner:
      field === "recording_turn_owner"
        ? value
        : selectedExpense.recording_turn_owner,
    concept: field === "concept" ? value : selectedExpense.concept,
    receipts: field === "receipts" ? value : selectedExpense.receipts,
    starred: field === "starred" ? value : selectedExpense.starred,
    deleted: field === "deleted" ? value : selectedExpense.deleted,
    reconciled: field === "reconciled" ? value : selectedExpense.reconciled,
    currency: field === "currency" ? value : selectedExpense.currency,
    method: field === "method" ? value : selectedExpense.method,
    expense_type:
      field === "expense_type" ? value : selectedExpense.expense_type,
  };

  return (dispatch) => {
    return expenseModifier
      .run(updatedExpense, field, files)
      .then((res) => {
        toastr.success("El gasto ha sido actualizado");
        dispatch({
          type: "UPDATE_EXPENSE",
          _id: _id,
          field: field,
          value: value,
        });
      })
      .catch((err) => {
        toastr.error("No se puedo actualizar el gasto");
        console.log(err);
      });
  };
};

export const expenseDetails = (_id) => {
  return function (dispatch) {
    dispatch({
      type: "EXPENSE_DETAILS",
      _id,
    });
  };
};

export const setEditExpenseFlag = (flag) => ({
  type: "EDIT_EXPENSE",
  flag,
});

export const setExpenseVisibilityFilter = (filter) => ({
  type: "SET_VISIBILITY_FILTER",
  filter,
});

export const expenseSearch = (searchTerm) => ({
  type: "FILTER_EXPENSE",
  payload: searchTerm,
});

export const toggleStarredExpense = (_id) => ({
  type: "TOGGLE_STARRED_EXPENSE",
  _id,
});

export const deleteExpense = (_id) => ({
  type: "DELETE_EXPENSE",
  _id,
});

export const destroyExpense = (_id: string) => {
  return async function (dispatch) {
    try {
      const response = await expenseDestroyer.run(_id);
      toastr.success("Se ha eliminado el gasto", { position: "bottom-right" });
      dispatch({
        type: "DESTROY_EXPENSE",
        _id: _id,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "DESTROY_EXPENSE",
        _id: "",
      });
    }
  };
};

export const destroyFile = (file_id: string) => {
  return fileDeleter
    .run(file_id)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
};

export function fetchExpenses() {
  return function (dispatch) {
    return indexExpenses
      .run()
      .then((data) => {
        console.log(data);
        dispatch({
          type: "GET_ALL_EXPENSES",
          expenses: data,
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        let errorMessage = "No se pudo obtener la lista de expenses";
        if (err.data) {
          console.log("HAY ERROR DATA");
          console.log(err);
          errorMessage = err.status + " " + err.statusText;
        } else {
          if (err.message) {
            console.log("HAY ERROR MESSAGE");
            errorMessage = err.message;
          } else {
            console.log("ERROR GENERICO INDEX");
          }
        }
        toastr.error(errorMessage);
        dispatch({
          type: "GET_ALL_EXPENSES",
          expenses: [],
        });
      });
  };
}

export const fetchReceiptFile = (file_id: string, file_name: string) => {
  console.log("ACTION");
  return fileGetter
    .run(file_id, file_name)
    .then((data) => {
      console.log("data", data);
      return data;
    })
    .catch((err) => {
      console.log(err);
      let errorMessage = "No se pudo obtener el archivo";
      if (err.data) {
        errorMessage = err.data.error;
      } else {
        if (err.message) {
          errorMessage = err.message;
        } else {
        }
      }
      toastr.error(errorMessage);
      return null;
    });
};

export const uploadReceiptFile = (expense, file) => {
  return fileCreator
    .run(expense, file, "")
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      let errorMessage = "No se pudo guardar el archivo";
      if (err.data) {
        errorMessage = err.data.error;
      } else {
        if (err.message) {
          errorMessage = err.message;
        } else {
        }
      }
      toastr.error(errorMessage);
      return null;
    });
};

export const expenseVisibilityFilter = {
  SHOW_ALL: "SHOW_ALL",
  DELETED_EXPENSE: "DELETED_EXPENSE",
  STARRED_EXPENSE: "STARRED_EXPENSE",
  RECONCILED_EXPENSE: "RECONCILED_EXPENSE",
  NOT_RECONCILED_EXPENSE: "NOT_RECONCILED_EXPENSE",
  SHOW_EXPENSE: "SHOW_EXPENSE",
  OTHER_EXPENSE: "OTHER_EXPENSE",
  RECORDING_STUDIO_EXPENSE: "RECORDING_STUDIO_EXPENSE",
  POST_PROD_EXPENSE: "POST_PROD_EXPENSE",
  PRODUCTION_EXPENSE: "PRODUCTION_EXPENSE",
  REPRESENTATION_EXPENSE: "REPRESENTATION_EXPENSE",
  EVENT_EXPENSE: "EVENT_EXPENSE",
  TRANSPORTATION_EXPENSE: "TRANSPORTATION_EXPENSE",
  PARKING_EXPENSE: "PARKING_EXPENSE",
  FEE_EXPENSE: "FEE_EXPENSE",
  PAYROLL_EXPENSE: "PAYROLL_EXPENSE",
  TAX_EXPENSE: "TAX_EXPENSE",
  RECORDING_EXPENSE: "RECORDING_EXPENSE",
  USD_EXPENSE: "USD_EXPENSE",
  BS_EXPENSE: "BS_EXPENSE",
  EURO_EXPENSE: "EURO_EXPENSE",
  CRYPTO_EXPENSE: "CRYPTO_EXPENSE",
  RENT_EXPENSE: "RENT_EXPENSE",
  MARKETING_EXPENSE: "MARKETING_EXPENSE",
  EDUCATION_EXPENSE: "EDUCATION_EXPENSE",
  SUPPLIES_EXPENSE: "SUPPLIES_EXPENSE",
};
