import { toastr } from "react-redux-toastr";
import { AxiosRecordingTurnRepository } from "../../../core/recordingturns/infraestructure/repository/AxiosRecordingTurnRepository";
import { AllRecordingTurnsGetter } from "../../../core/recordingturns/application/use_cases/AllRecordingTurnsGetter";
import { RecordingTurnCreator } from "../../../core/recordingturns/application/use_cases/RecordingTurnCreator";
import { RecordingTurnModifier } from "../../../core/recordingturns/application/use_cases/RecordingTurnModifier";
import { RecordingTurnDestroyer } from "../../../core/recordingturns/application/use_cases/RecordingTurnDestroyer";
import { RecordingTurnRepository } from "../../../core/recordingturns/domain/RecordingTurnRepository";

const recordingTurnRepository: RecordingTurnRepository =
  new AxiosRecordingTurnRepository();
const indexRecordingTurns = new AllRecordingTurnsGetter(
  recordingTurnRepository
);
const createRecordingTurn = new RecordingTurnCreator(recordingTurnRepository);
const recordingTurnModifier = new RecordingTurnModifier(
  recordingTurnRepository
);
const recordingTurnDestroyer = new RecordingTurnDestroyer(
  recordingTurnRepository
);

export const addRecordingTurn = (
  _id: string,
  owner_id: string, // user id of owner
  price: Number,
  description: string,
  date: Date,
  startTime: Date,
  endTime: Date,
  starred: Boolean,
  deleted: Boolean,
  status: string,
  payments: string[],
  turnTeam: string[],
  approved: Boolean,
  advertisers?: string[],
  expenses?: string[],
  seed?: any
) => {
  let newRecordingTurn = {
    _id: undefined,
    owner_id: owner_id ? owner_id : "",
    price: price ? price : 0,
    description: description ? description : "",
    date: date ? date : new Date(Date.now()),
    startTime: startTime ? startTime : new Date(Date.now()),
    endTime: endTime ? endTime : new Date(Date.now()),
    starred: false,
    deleted: false,
    status: status ? status : "",
    payments: payments ? payments : [],
    createdAt: undefined,
    turnTeam: turnTeam ? turnTeam : [],
    approved: approved ? approved : false,
    advertisers: advertisers ? advertisers : [],
    expenses: expenses ? expenses : [],
  };

  return (dispatch) => {
    return createRecordingTurn
      .run(newRecordingTurn)
      .then((res) => {
        toastr.success("Se ha guardado el recordingTurn");
        dispatch({
          type: "ADD_RECORDING_TURN",
          _id: res._id,
          owner_id: res.owner_id,
          price: res.price,
          description: res.description,
          date: res.date,
          startTime: res.startTime,
          endTime: res.endTime,
          starred: res.starred,
          deleted: res.deleted,
          status: res.status,
          payments: res.payments,
          turnTeam: res.turnTeam,
          approved: res.approved,
          advertisers: res.advertisers,
          expenses: res.expenses,
        });
        seed();
      })

      .catch((error) => {
        console.log(error);
        toastr.error("Hubo un problema");
        dispatch({
          type: "ADD_RECORDING_TURN",
          _id: _id ? _id : "",
          owner_id: owner_id ? owner_id : "",
          price: price ? price : 0,
          description: description ? description : "",
          date: date ? date : new Date(Date.now()),
          startTime: startTime ? startTime : new Date(Date.now()).toUTCString(),
          endTime: endTime ? endTime : new Date(Date.now()).toUTCString(),
          starred: false,
          deleted: false,
          status: status ? status : "",
          payments: payments ? payments : [],
          turnTeam: turnTeam ? turnTeam : [],
          approved: approved ? approved : false,
          advertisers: advertisers ? advertisers : [],
          expenses: expenses ? expenses : [],
        });
      });
  };
};

export const updateRecordingTurn = (_id, field, value) => {
  console.log(value);
  const updateRequest = {
    _id: _id,
    field: field,
    value: value,
  };

  return (dispatch) => {
    return recordingTurnModifier
      .run(updateRequest)
      .then((res) => {
        toastr.success("La pauta ha sido actualizada");
        dispatch({
          type: "UPDATE_RECORDING_TURN",
          _id: _id,
          field: field,
          value: value,
        });
      })
      .catch((err) => {
        toastr.error("No se puedo actualizar la pauta");
        console.log(err);
      });
  };
};

export const updateRecordingTurnFc = async (_id, field, value) => {
  const updateRequest = {
    _id: _id,
    field: field,
    value: value,
  };

  try {
    const res = await recordingTurnModifier.run(updateRequest);
    toastr.success("La pauta ha sido actualizada");
    return res;
  } catch (error) {
    toastr.error("No se puedo actualizar la pauta");
    console.log(error);
  }
};

export const recordingTurnDetails = (_id) => {
  return function (dispatch) {
    dispatch({
      type: "RECORDING_TURN_DETAILS",
      _id,
    });
  };
};

export const setEditRecordingTurnFlag = (flag) => ({
  type: "EDIT_RECORDING_TURN",
  flag,
});

export const setRecordingTurnVisibilityFilter = (filter) => ({
  type: "SET_VISIBILITY_FILTER",
  filter,
});

export const recordingTurnSearch = (searchTerm) => ({
  type: "FILTER_RECORDING_TURN",
  payload: searchTerm,
});

export const toggleStarredRecordingTurn = (_id) => ({
  type: "TOGGLE_STARRED_RECORDING_TURN",
  _id,
});

export const toggleSuspendRecordingTurn = (_id) => ({
  type: "TOGGLE_SUSPEND_RECORDING_TURN",
  _id,
});

export const deleteRecordingTurn = (_id) => ({
  type: "DELETE_RECORDING_TURN",
  _id,
});

export const destroyRecordingTurn = (_id) => {
  return function (dispatch) {
    return recordingTurnDestroyer
      .run(_id)
      .then((response) => {
        dispatch({
          type: "DESTROY_RECORDING_TURN",
          _id,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "DESTROY_RECORDING_TURN",
          _id,
        });
      });
  };
};

export function fetchRecordingTurns() {
  return function (dispatch) {
    return indexRecordingTurns
      .run()
      .then((data) => {
        dispatch({
          type: "GET_ALL_RECORDING_TURNS",
          recordingTurns: data,
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        let errorMessage = "No se pudo obtener la lista de recordingTurns";
        if (err.data) {
          console.log("HAY ERROR DATA");
          console.log(err);
          errorMessage = err.status + " " + err.statusText;
        } else {
          if (err.message) {
            console.log("HAY ERROR MESSAGE");
            errorMessage = err.message;
          } else {
            console.log("ERROR GENERICO INDEX");
          }
        }
        toastr.error(errorMessage);
        dispatch({
          type: "GET_ALL_RECORDING_TURNS",
          recordingTurns: [],
        });
      });
  };
}

export const recordingTurnVisibilityFilter = {
  SHOW_ALL: "SHOW_ALL",
  DELETED_RECORDING_TURN: "DELETED_RECORDING_TURN",
  STARRED_RECORDING_TURN: "STARRED_RECORDING_TURN",
  OTHER_RECORDING_TURN: "OTHER_RECORDING_TURN",
  SUSPENDED_RECORDING_TURN: "SUSPENDED_RECORDING_TURN",
  RECONCILED_RECORDING_TURN: "RECONCILED_RECORDING_TURN",
  APPROVED_RECORDING_TURN: "APPROVED_RECORDING_TURN",
  DONE: "DONE",
};
