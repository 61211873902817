import Client from "../../../domain/Client";
import { ClientRepository } from "../../../domain/ClientRepository";
import { UpdateClientRequest } from "./UpdateClientRequest";

export class ClientModifier {
  private repository: ClientRepository;
  request: UpdateClientRequest;

  constructor(repository: ClientRepository) {
    this.repository = repository;
  }

  async run(request): Promise<Client | Error> {
    return await this.repository.update(
      request.id,
      request.field,
      request.value
    );
  }
}
