import Campaign from "../../domain/Campaign";
import { CampaignRepository } from "../../domain/CampaignRepository";

export class CampaignDestroyer {
  private repository: CampaignRepository;

  constructor(repository: CampaignRepository) {
    this.repository = repository;
  }

  async run(id: string): Promise<Campaign> {
    try {
      return await this.repository.destroy(id);
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
