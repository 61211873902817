import { employeeTurnVisiblityFilter } from "../../actions/emoployeeTurns";

const EmployeeTurnsVisiblityFilter = (
  state = employeeTurnVisiblityFilter.SHOW_ALL,
  action
) => {
  switch (action.type) {
    case "SET_VISIBILITY_FILTER":
      return action.filter;
    default:
      return state;
  }
};

export default EmployeeTurnsVisiblityFilter;
