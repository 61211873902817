const postTags = (state = [], action) => {
  //console.log(action);
  switch (action.type) {
    case "ADD_POST_TAG":
      return [
        ...state,
        {
          _id: action._id,
          tag: action.tag,
        },
      ];

    case "GET_ALL_POSTS_TAGS":
      return action.postTags;

    default:
      return state;
  }
};

export default postTags;
