import { ToDo } from "../../../domain/ToDo";
import { ToDoRepository } from "../../../domain/ToDoRepository";
import { UpdateToDoRequest } from "./UpdateToDoRequest";

export class TodoModifier {
  private repository: ToDoRepository;
  request: UpdateToDoRequest;

  constructor(repository: ToDoRepository) {
    this.repository = repository;
  }

  async run(request: UpdateToDoRequest): Promise<ToDo> {
    const todoUpdate = ToDo.create(
      request._id,
      request.toDo_id,
      request.full_name,
      request.task,
      request.owner_id,
      request.project,
      request.image,
      request.images,
      request.start_date,
      request.due_date,
      request.tags,
      request.comments,
      request.priority,
      request.department,
      request.starred,
      request.deleted,
      request.updatedBy,
      request.slug,
      request.completed,
      request.status
    );

    return await this.repository.updateToDo(todoUpdate);
  }
}
