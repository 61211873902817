const initialState = {
  tasks: [],
};

const GetAllTasksProgressPerPersonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TASKS_PROGRESS_PP":
      return {
        tasks: action.tasks,
      };
    default:
      return state;
  }
};

export default GetAllTasksProgressPerPersonReducer;
