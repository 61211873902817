let userList = [];

/**
 * Manages the state of the users List
 * @param {*} state
 * @param {*} action
 */
const users = (state = userList, action) => {
  switch (action.type) {
    case "ADD_USER":
      //console.log("USERS FETCH USERS RED 4", action);
      if (action.message && action.message === "Usuario agregado con éxito") {
        //console.log("SE AGREGÓ");
        return [
          ...state,
          {
            _id: action._id,
            name: action.name,
            lastName: action.lastName,
            bio: action.bio,
            image: action.image,
            department: action.department,
            company: action.company,
            socials: action.socials,
            phone: action.phone,
            email: action.email,
            dni: action.dni,
            isActive: true,
            address: action.address,
            notes: action.notes,
            gender: action.gender,
            images: action.images,
            roles: action.roles,
            isStarred: false,
            isDeleted: false,
            updatedBy: "",
            slug: action.slug,
          },
        ];
      } else {
        console.log("No SE AGREGO REDUCER");
        console.log(action);
        return [...state];
      }

    case "UPDATE_USER":
      return state.map((user) =>
        user._id === action._id
          ? { ...user, [action.field]: action.value }
          : user
      );

    case "TOGGLE_STARRED_USER":
      return state.map((user) =>
        user._id === action._id ? { ...user, isStarred: !user.isStarred } : user
      );

    case "GET_ALL_USERS":
      return action.users;

    case "DELETE_USER":
      return state.map((user) =>
        user._id === action._id ? { ...user, isDeleted: !user.isDeleted } : user
      );

    default:
      return state;
  }
};

export default users;
