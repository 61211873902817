export default class RecordingTurn {
  _id: string;
  owner_id: string; // user id of owner (responsible)
  price: Number;
  description: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  starred: Boolean;
  deleted: Boolean;
  status: string;
  payments: string[]; // associated payments ids
  createdAt: Date;
  turnTeam: string[]; // team ids
  approved: Boolean;
  advertisers: string[]; // id of the advertiser user (company)
  expenses: string[]; // id of the expenses

  constructor(
    _id: string,
    owner_id: string, // user id of owner
    price: Number,
    description: string,
    date: Date,
    startTime: Date,
    endTime: Date,
    starred: Boolean,
    deleted: Boolean,
    status: string,
    payments: string[], // associated payments ids
    createdAt: Date,
    turnTeam: string[], // team ids
    approved: Boolean,
    advertisers: string[], // contact of the advertiser (company)
    expenses: string[] // id of the expenses
  ) {
    this._id = _id;
    this.owner_id = owner_id;
    this.price = price;
    this.description = description;
    this.date = date;
    this.startTime = startTime;
    this.endTime = endTime;
    this.status = status;
    this.starred = starred;
    this.deleted = deleted;
    this.payments = payments;
    this.createdAt = createdAt;
    this.turnTeam = turnTeam;
    this.approved = approved;
    this.advertisers = advertisers;
    this.expenses = expenses;
  }

  static create(
    _id: string,
    owner_id: string, // user id of owner
    price: Number,
    description: string,
    date: Date,
    startTime: Date,
    endTime: Date,
    starred: Boolean,
    deleted: Boolean,
    status: string,
    payments: string[], // associated payments ids
    createdAt: Date,
    turnTeam: string[],
    approved: Boolean,
    advertisers: string[], // contact of the advertiser (company)
    expenses: string[] // id of the expenses
  ) {
    return new RecordingTurn(
      _id,
      owner_id,
      price,
      description,
      date,
      startTime,
      endTime,
      starred,
      deleted,
      status,
      payments,
      createdAt,
      turnTeam,
      approved,
      advertisers,
      expenses
    );
  }
}
