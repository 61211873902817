export const telegramAuthenticate = (state = false, action) => {
  switch (action.type) {
    case "HANDLE_TELEGRAM_AUTHENTICATION":
      return action.isTelegramAuthenticated;
    default:
      return state;
  }
};

export const fetchTelegramMessages = (state = [], action) => {
  switch (action.type) {
    case "FETCH_USER_TELEGRAM_MESSAGES":
      return action.telegramMessages;
    default:
      return state;
  }
};

export const messageHistory = (state = [], action) => {
  switch (action.type) {
    case "FETCH_MESSAGE_HISTORY":
      return action.messageHistory;
    case "SEND_MSG":
      return action.messageHistory !== [] ? action.messageHistory : state;
    default:
      return state;
  }
};
