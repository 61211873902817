const initialState = {
  themes: [],
};

const GetPostThemesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TOPICS_PERCENT":
      return {
        themes: action.themes,
      };
    default:
      return state;
  }
};

export default GetPostThemesReducer;
