export const apiUrl = "https://api.tnoradio.com/api/";

export const filesUrl = "https://files.tnoradio.com/";

export const imagesUrl = "https://images.tnonetwork.com/api/images/";

export const usersUrl = "https://users.tnonetwork.com/api/users";

export const showsUrl = "https://shows.tnonetwork.com/api/shows";

export const episodesUrl = "https://ondemand.tnoradio.com";

export const miniaturesUrl =
  "https://files.tnoradio.com/shows/ondemand_episode_miniature";
