import { combineReducers } from "redux";
import clients from "./clients";
import clientDetailsReducer from "./clientDetailsReducer";
import clientVisibilityFilter from "./clientVisibilityFilter";
import clientSearch from "./clientSearch";
import editClient from "./editClient";
import agencies from "./agencies";
import agencyDetailsReducer from "./agencyDetailsReducer";

export default combineReducers({
  clients,
  clientDetailsReducer,
  clientVisibilityFilter,
  clientSearch,
  editClient,
  agencies,
  agencyDetailsReducer,
});
