let notifications = [];

const notificationsReducer = (state = notifications, action) => {
  //console.log(action.notifications);
  switch (action.type) {
    case "ADD_USER_NOTIFICATION":
      return [...state, action.notification];
    case "GET_USER_NOTIFICATIONS":
      return action.notifications?.reverse();
    case "UPDATE_NOTIFICATION_STATE":
      return state.map((notification) =>
        notification._id === action._id
          ? { ...notification, [action.field]: action.value }
          : notification
      );
    default:
      return state;
  }
};

export default notificationsReducer;
