import Payment from "../../../domain/Payment";
import { PaymentRepository } from "../../../domain/PaymentRepository";

export class AllPaymentsGetter {
  private repository: PaymentRepository;

  constructor(repository: PaymentRepository) {
    this.repository = repository;
  }

  async run(): Promise<Payment[] | Error> {
    return await this.repository.index();
  }
}
