const initialState = {
  showDetail: {},
};

const fetchShowDetail = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SHOW_DETAIL":
      return { ...state, showDetail: action.showDetail };
    default:
      return state;
  }
};

export default fetchShowDetail;
