import { UserRepository } from "../../../core/users/domain/UserRepository";
//import { notificationDetailGetter } from '../../../core/notifications/application/use_cases/getnotificationDetail/notificationDetailtGetter'
import { toastr } from "react-redux-toastr";
import { AxiosUserRepository } from "../../../core/users/infrastructure/repository/AxiosUserRepository";
import { EmployeeTurnCreator } from "../../../core/users/application/use_cases/createemployeeturn/EmployeeTurnCreator";
import { EmployeeTurnModifier } from "../../../core/users/application/use_cases/updateemployeeturn/EmployeeTurnModifier";
import { EmployeeTurnsGetter } from "../../../core/users/application/use_cases/getemployeeturns/EmployeeTurnsGetter";
import { EmployeeTurnDestroyer } from "../../../core/users/application/use_cases/deleterecordingturn/EmployeeTurnDestroyer";
import EmployeeActivity from "../../../core/users/domain/EmployeeActivity";

const userRepository: UserRepository = new AxiosUserRepository();
const getEmployeeTurns = new EmployeeTurnsGetter(userRepository);
const createEmployeeTurn = new EmployeeTurnCreator(userRepository);
const employeeTurnModifier = new EmployeeTurnModifier(userRepository);
const employeeTurnDestroyer = new EmployeeTurnDestroyer(userRepository);

export const addEmployeeTurn = (
  startTime: Date,
  endTime: Date,
  owner: string,
  date: Date,
  activities: EmployeeActivity[],
  seed: any
) => {
  let newEmployeeTurn = {
    _id: undefined,
    startTime: startTime ? startTime : new Date(Date.now()),
    endTime: endTime ? endTime : new Date(Date.now()),
    owner: owner ? owner : "",
    date: date ? date : new Date(Date.now()),
    activities: activities ? activities : [],
  };

  return (dispatch) => {
    return createEmployeeTurn
      .run(newEmployeeTurn)
      .then((res) => {
        toastr.success("Se ha registrado un turno de empleado");
        dispatch({
          type: "ADD_EMPLOYEE_TURN",
          employeeTurn: {
            _id: res._id,
            startTime: res.startTime,
            endTime: res.endTime,
            owner: res.owner,
            date: res.date,
            activities: res.activities,
          },
        });
        seed();
      })
      .catch((error) => {
        console.log(error);
        toastr.error("Hubo un problema");
        dispatch({
          type: "ADD_EMPLOYEE_TURN",
          notification: {
            _id: "",
            startTime: startTime ? startTime : new Date(Date.now()),
            endTime: endTime ? endTime : new Date(Date.now()),
            owner: owner ? owner : "",
            date: date ? date : new Date(Date.now()),
            activities: activities ? activities : [],
          },
        });
      });
  };
};

export const updateEmployeeTurn = (_id, field, value) => {
  const updateValues = {
    _id: _id,
    field: field,
    value: value,
  };

  return (dispatch) =>
    employeeTurnModifier
      .run(updateValues)
      .then((res) => {
        toastr.success("Ha terminado el turno de trabajo");
        dispatch({
          type: "UPDATE_EMPLOYEE_TURN",
          _id: _id,
          field: field,
          value: value,
        });
      })
      .catch((err) => {
        //toastr.error("No se puedo actualizar el contenido");
        console.log(err);
      });
};

export function fetchEmployeeTurns(userId: string) {
  console.log("GET_EMPLOYEE_TURNS");
  return function (dispatch) {
    return getEmployeeTurns
      .run(userId)
      .then((data) => {
        console.log(data);
        dispatch({
          type: "GET_EMPLOYEE_TURNS",
          employeeTurns: data.reverse(),
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        let errorMessage = "No se pudo obtener la lista de turnos";
        if (err.data) {
          console.log("HAY ERROR DATA");
          console.log(err);
          errorMessage = err.status + " " + err.statusText;
        } else {
          if (err.message) {
            console.log("HAY ERROR MESSAGE");
            errorMessage = err.message;
          } else {
            console.log("ERROR GENERICO INDEX");
          }
        }
        //toastr.error(errorMessage);
        dispatch({
          type: "GET_EMPLOYEE_TURNS",
          employeeTurns: [],
        });
      });
  };
}

export const setVisibilityFilter = (filter) => ({
  type: "SET_VISIBILITY_FILTER",
  filter,
});

export const employeeTurnsSearch = (searchTerm) => ({
  type: "FILTER_EMPLOYEE_TURN",
  payload: searchTerm,
});

export const employeeTurnVisiblityFilter = {
  SHOW_ALL: "SHOW_ALL",
};

export const deleteEmployeeTurn = (_id) => {
  return function (dispatch) {
    return employeeTurnDestroyer
      .run(_id)
      .then((response) => {
        dispatch({
          type: "DESTROY_EMPLOYEE_TURN",
          _id,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "DESTROY_EMPLOYEE_TURN",
          _id,
        });
      });
  };
};
