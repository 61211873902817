const initialState = {
  users: [],
};

const fetchUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_USERS":
      return { ...state, users: action.users };
    default:
      return state;
  }
};

export default fetchUsersReducer;
