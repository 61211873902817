let show = {
  isErrorName: false,
  isErrorSynopsis: false,
  isErrorEmail: false,
  isErrorNotScheduleSelected: false,
  isErrorProducer: false,
  isErrorHost: false,
  isErrorAdvertaiser: false,
  isRegister: false,
};

const showAddFieldsErrors = (state = show, action) => {
  switch (action.type) {
    case "UPDATE":
      state = {
        ...state,
        ...action,
      };
      return state;
    default:
      return state;
  }
};

export default showAddFieldsErrors;
