import { combineReducers } from "redux";
import expenses from "./expense";
import expenseDetails from "./expenseDetails";
import expenseVisibilityFilter from "./expenseVisibilityFilter";
import expenseSearch from "./expenseSearch";
import editExpense from "./editExpense";

export default combineReducers({
  expenses,
  expenseDetails,
  expenseVisibilityFilter,
  expenseSearch,
  editExpense,
});
