const agencyDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case "AGENCY_DETAILS":
      return action._id;
    default:
      return state;
  }
};

export default agencyDetailsReducer;
