const initialState = {
  showBanner: {},
  showMiniBanner: {},
  showResponsiveBanner: {},
  showMiniSiteBanner: {},
};

const fetchShowImage = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SHOW_IMAGE":
      if (action.showImage.name === "showBanner")
        return { ...state, showBanner: action.showImage };
      if (action.showImage.name === "showMiniBanner")
        return { ...state, showMiniBanner: action.showImage };
      if (action.showImage.name === "showMiniSiteBanner")
        return { ...state, showMiniSiteBanner: action.showImage };
      if (action.showImage.name === "responsiveMiniSiteBanner")
        return { ...state, responsiveMiniSiteBanner: action.showImage };
      if (action.showImage.name === "showResponsiveBanner")
        return { ...state, showResponsiveBanner: action.showImage };
    default:
      return state;
  }
};

export default fetchShowImage;
