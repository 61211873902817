import { combineReducers } from "redux";
import shows from "./show";
import showDetails from "./showDetails";
import showDetail from "./fetchShowDetail";
import showsVisibilityFilter from "./showsVisibilityFilter";
import showsSearch from "./showsSearch";
import editShow from "./editShow";
import fetchShows from "./fetchShows";
import showGenres from "./showGenres";
import showAdvertisers from "./showAdvertisers";
import showPGs from "./showPGClass";
import showTypes from "./showTypes";
import showAddFields from "./showAddFields";
import updateShowImage from "./updateShowImage";
import fetchShowImage from "./fetchShowImage";
import showSubGenres from "./showSubGenres";
import showSeasonsWithEpisodes from "./fetchShowSeasonsWithEpisodes";
import showFileNames from "./fetchShowFileNames";

export default combineReducers({
  shows,
  showDetails,
  showDetail,
  showsVisibilityFilter,
  showsSearch,
  editShow,
  fetchShows,
  showGenres,
  showPGs,
  showTypes,
  showAddFields,
  updateShowImage,
  fetchShowImage,
  showAdvertisers,
  showSubGenres,
  showSeasonsWithEpisodes,
  showFileNames,
});
