const initialState = {
  shows: [],
  recommendedShows: [],
};

const fetchShows = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SHOWS":
      return { ...state, shows: action.shows };
    case "GET_RECOMMENDED_SHOWS":
      return { ...state, recommendedShows: action.recommendedShows };
    default:
      return state;
  }
};

export default fetchShows;
