import { ToDoImage } from "./ToDoImage";

export class ToDo {
  _id: string;
  toDo_id: string; // Attribute just for toDo_history
  full_name: string;
  task: string;
  owner_id: string; // user id of owner
  project: string;
  image: string;
  images: ToDoImage[];
  start_date: Date;
  due_date: Date;
  tags: string[];
  comments: string;
  priority: Boolean;
  department: string;
  starred: Boolean;
  deleted: Boolean;
  updatedBy: string;
  slug: string;
  completed: Boolean;
  status: string;

  constructor(
    _id: string,
    toDo_id: string, // Attribute just for toDo_history
    full_name: string,
    task: string,
    owner_id: string, // user id of owner
    project: string,
    image: string,
    images: ToDoImage[],
    start_date: Date,
    due_date: Date,
    tags: string[],
    comments: string,
    priority: Boolean,
    department: string,
    starred: Boolean,
    deleted: Boolean,
    updatedBy: string,
    slug: string,
    completed: Boolean,
    status: string
  ) {
    this._id = _id;
    this.full_name = full_name;
    this.task = task;
    this.owner_id = owner_id;
    this.project = project;
    this.image = image;
    this.images = images;
    this.start_date = start_date;
    this.due_date = due_date;
    this.tags = tags;
    this.priority = priority;
    this.comments = comments;
    this.department = department;
    this.images = images;
    this.starred = starred;
    this.deleted = deleted;
    this.updatedBy = updatedBy;
    this.toDo_id = toDo_id;
    this.slug = slug;
    this.completed = completed;
    this.status = status;
  }

  static create(
    _id: string,
    full_name: string,
    task: string,
    toDo_id: string, // Attribute just for toDo_history
    owner_id: string, // user id of owner
    project: string,
    image: string,
    images: ToDoImage[],
    start_date: Date,
    due_date: Date,
    tags: string[],
    comments: string,
    priority: Boolean,
    department: string,
    starred: Boolean,
    deleted: Boolean,
    updatedBy: string,
    slug: string,
    completed: Boolean,
    status: string
  ) {
    return new ToDo(
      _id,
      full_name,
      task,
      toDo_id,
      owner_id,
      project,
      image,
      images,
      start_date,
      due_date,
      tags,
      comments,
      priority,
      department,
      starred,
      deleted,
      updatedBy,
      slug,
      completed,
      status
    );
  }
}
