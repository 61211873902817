const showFileNames = (state = [], action) => {
  switch (action.type) {
    case "FETCH_SHOW_FILE_NAMES":
      return action.showFileNames;

    default:
      return state;
  }
};

export default showFileNames;
