import Channel from "./Channel";

export default class Campaign {
  _id: string;
  name: string;
  price: Number;
  owner_id: string; // user id of owner, person that added the campaign
  start_date: Date;
  end_date: Date;
  channel: Channel[];
  starred: Boolean;
  deleted: Boolean;
  isActive: Boolean;
  owner: string;
  payments: string[];

  constructor(
    _id: string,
    name: string,
    price: Number,
    owner_id: string, // user id of owner, person that added the campaign
    start_date: Date,
    end_date: Date,
    channel: Channel[],
    starred: Boolean,
    deleted: Boolean,
    isActive: Boolean,
    owner: string,
    payments: string[]
  ) {
    this._id = _id;
    this.name = name;
    this.price = price;
    this.owner_id = owner_id;
    this.start_date = start_date;
    this.end_date = end_date;
    this.channel = channel;
    this.starred = starred;
    this.deleted = deleted;
    this.isActive = isActive;
    this.owner = owner;
    this.payments = payments;
  }

  static create(
    _id: string,
    name: string,
    price: Number,
    owner_id: string, // user id of owner, person that added the campaign
    start_date: Date,
    end_date: Date,
    channel: Channel[],
    starred: Boolean,
    deleted: Boolean,
    isActive: Boolean,
    owner: string,
    payments: string[]
  ) {
    return new Campaign(
      _id,
      name,
      price,
      owner_id,
      start_date,
      end_date,
      channel,
      starred,
      deleted,
      isActive,
      owner,
      payments
    );
  }
}
