const UpdateUserImage = (
  state = { userImage: {}, updateUserProfileImage: {} },
  action
) => {
  switch (action.type) {
    case "UPDATE_USER_IMAGE":
      return { ...state, userImage: action.userImage };
    case "UPDATE_USER_PROFILE_IMAGE":
      return { ...state, userProfileImage: action.userProfileImage };
    default:
      return state;
  }
};
export default UpdateUserImage;
