import Client from "../../../domain/Client";
import { ClientRepository } from "../../../domain/ClientRepository";

export class AllClientsGetter {
  private repository: ClientRepository;

  constructor(repository: ClientRepository) {
    this.repository = repository;
  }

  async run(): Promise<Client[] | Error> {
    return await this.repository.index();
  }
}
