import Receipt from "./Receipt";

export default class Expense {
  _id: string;
  amountBs: Number;
  amountUsd: Number;
  amountEuro: Number;
  amountCripto: Number;
  amountOther: Number;
  owner_id: string; // user id of owner, person that added the expense
  recording_turn_owner: string; //if is fro a show
  concept: string;
  currency: string;
  receipts: Receipt[];
  date: Date;
  starred: Boolean;
  deleted: Boolean;
  reconciled: Boolean;
  method: string;
  expense_type: string;

  constructor(
    _id: string,
    amountBs: Number,
    amountUsd: Number,
    amountEuro: Number,
    amountCripto: Number,
    amountOther: Number,
    owner_id: string, // user id of owner
    recording_turn_owner: string,
    concept: string,
    receipts: Receipt[],
    date: Date,
    starred: Boolean,
    deleted: Boolean,
    reconciled: Boolean,
    currency: string,
    method: string,
    expense_type: string
  ) {
    this._id = _id;
    this.amountBs = amountBs;
    this.amountUsd = amountUsd;
    this.amountEuro = amountEuro;
    this.amountCripto = amountCripto;
    this.amountOther = amountOther;
    this.concept = concept;
    this.owner_id = owner_id;
    this.recording_turn_owner = recording_turn_owner;
    this.date = date;
    this.receipts = receipts;
    this.starred = starred;
    this.deleted = deleted;
    this.reconciled = reconciled;
    this.currency = currency;
    this.method = method;
    this.expense_type = expense_type;
  }

  static create(
    _id: string,
    amountBs: Number,
    amountUsd: Number,
    amountEuro: Number,
    amountCripto: Number,
    amountOther: Number,
    owner_id: string, // user id of owner
    recording_turn_owner: string,
    concept: string,
    receipts: Receipt[],
    date: Date,
    starred: Boolean,
    deleted: Boolean,
    reconciled: Boolean,
    currency: string,
    method: string,
    expense_type: string
  ) {
    return new Expense(
      _id,
      amountBs,
      amountUsd,
      amountEuro,
      amountCripto,
      amountOther,
      owner_id,
      recording_turn_owner,
      concept,
      receipts,
      date,
      starred,
      deleted,
      reconciled,
      currency,
      method,
      expense_type
    );
  }
}
