import { combineReducers } from "redux";
import campaigns from "./campaign";
import campaignDetails from "./campaignDetails";
import buyerPerson from "./buyerPerson";
import campaignVisibilityFilter from "./campaignVisibilityFilter";
import campaignSearch from "./campaignSearch";
import editCampaign from "./editCampaign";

export default combineReducers({
  campaigns,
  campaignDetails,
  campaignVisibilityFilter,
  campaignSearch,
  editCampaign,
  buyerPerson,
});
