const initialState = {
  topicMetrics: [],
};

const GetAllTopicMetricsNaiveBayesReducer = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case "GET_ALL_TOPIC_METRICS":
      return {
        topicMetrics: action.topicMetrics,
      };
    default:
      return state;
  }
};

export default GetAllTopicMetricsNaiveBayesReducer;
