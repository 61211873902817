let expensesList = [];

const expenses = (state = expensesList, action) => {
  switch (action.type) {
    case "ADD_EXPENSE":
      return [
        ...state,
        {
          _id: action._id,
          amountBs: action.amountBs,
          amountUsd: action.amountUsd,
          amountEuro: action.amountEuro,
          amountCripto: action.amountCripto,
          amountOther: action.amountOther,
          owner_id: action.owner_id,
          concept: action.concept,
          receipts: action.receipts,
          date: action.date,
          starred: false,
          deleted: false,
          reconciled: false,
          currency: action.currency,
          method: action.method,
          type: action.expenseType,
        },
      ];

    case "UPDATE_EXPENSE":
      return state.map((expense) =>
        expense._id === action._id
          ? { ...expense, [action.field]: action.value }
          : expense
      );

    case "TOGGLE_RECONCILE_EXPENSE":
      return state.map((expense) =>
        expense._id === action._id
          ? { ...expense, reconciled: !expense.reconciled }
          : expense
      );

    case "TOGGLE_STARRED_EXPENSE":
      return state.map((expense) =>
        expense._id === action._id
          ? { ...expense, starred: !expense.starred }
          : expense
      );

    case "DESTROY_EXPENSE":
      console.log(action._id);
      return state.filter((expense) => expense._id !== action._id);

    case "GET_ALL_EXPENSES":
      return action.expenses;

    case "GET_USER_EXPENSES":
      return action.expenses;

    default:
      return state;
  }
};

export default expenses;
