import { ToDoRepository } from "../../../domain/ToDoRepository";
import { ToDo } from "../../../domain/ToDo";

export class UserToDosGetter {
  private repository: ToDoRepository;

  constructor(repository: ToDoRepository) {
    this.repository = repository;
  }

  async run(owner: string): Promise<ToDo[]> {
    return await this.repository.getUserTodos(owner);
  }
}
