let paymentsList = [];

const payments = (state = paymentsList, action) => {
  switch (action.type) {
    case "ADD_PAYMENT":
      return [
        ...state,
        {
          _id: action._id,
          amount: action.amount,
          owner_id: action.owner_id,
          show_owner: action.show_owner,
          concept: action.concept,
          receipts: action.receipts,
          payment_date: action.payment_date,
          starred: false,
          deleted: false,
          reconciled: false,
          currency: action.currency,
          method: action.method,
        },
      ];

    case "UPDATE_PAYMENT":
      return state.map((payment) =>
        payment._id === action._id
          ? { ...payment, [action.field]: action.value }
          : payment
      );

    case "TOGGLE_RECONCILE_PAYMENT":
      return state.map((payment) =>
        payment._id === action._id
          ? { ...payment, reconciled: !payment.reconciled }
          : payment
      );

    case "TOGGLE_STARRED_PAYMENT":
      return state.map((payment) =>
        payment._id === action._id
          ? { ...payment, starred: !payment.starred }
          : payment
      );

    case "DESTROY_PAYMENT":
      return state.map((payment) =>
        payment._id === action._id
          ? { ...payment, deleted: !payment.deleted }
          : payment
      );

    case "GET_ALL_PAYMENTS":
      return action.payments;

    case "GET_USER_PAYMENTS":
      return action.payments;

    default:
      return state;
  }
};

export default payments;
