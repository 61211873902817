const initialState = {
  usersClassified: [],
};

const GetUsersClassifiedByTopicReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS_CLASSIFIED_BY_TOPIC":
      return {
        usersClassified: action.usersClassified,
      };
    default:
      return state;
  }
};

export default GetUsersClassifiedByTopicReducer;
