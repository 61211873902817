const initialState = {
  published: [],
};

const GetAllPublishedFacebookReducer = (state = initialState, action) => {
  //console.log(action)
  switch (action.type) {
    case "FETCH_PUBLISHED_FACEBOOK":
      return {
        published: action.published,
      };
    default:
      return state;
  }
};

export default GetAllPublishedFacebookReducer;
