let clientsList = [];

const clients = (state = clientsList, action) => {
  switch (action.type) {
    case "ADD_CLIENT":
      return [
        ...state,
        {
          _id: action._id,
          price: action.price,
          owner_id: action.owner_id,
          description: action.description,
          date: action.date,
          startTime: action.startTime,
          endTime: action.endTime,
          starred: false,
          deleted: false,
          status: "",
          payments: action.payments,
        },
      ];

    case "UPDATE_CLIENT":
      return state.map((client) =>
        client._id === action._id
          ? { ...client, [action.field]: action.value }
          : client
      );

    case "UPDATE_IS_USER_CLIENT":
      return state.map((client) =>
        client.pcustomer_user_id === action.id
          ? { ...client, pcustomer_isUser: !client.pcustomer_isUser }
          : client
      );

    case "TOGGLE_CLIENT_STATUS":
      return state.map((client) =>
        client._id === action._id
          ? { ...client, status: !client.status }
          : client
      );

    case "TOGGLE_STARRED_CLIENT":
      return state.map((client) =>
        client._id === action._id
          ? { ...client, starred: !client.starred }
          : client
      );

    case "TOGGLE_SUSPEND_CLIENT":
      return state.map((client) =>
        client._id === action._id
          ? { ...client, isSuspended: !client.isSuspended }
          : client
      );

    case "DESTROY_CLIENT":
      return state.map((client) =>
        client._id === action._id
          ? { ...client, deleted: !client.deleted }
          : client
      );

    case "GET_ALL_CLIENTS":
      return action.clients;

    case "GET_USER_CLIENTS":
      return action.clients;

    default:
      return state;
  }
};

export default clients;
