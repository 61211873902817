const initialState = [];

const showGenres = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SHOW_GENRES":
      return [...action.showGenres];
    default:
      return state;
  }
};

export default showGenres;
