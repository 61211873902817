import { CampaignRepository } from "../../../core/campaigns/domain/CampaignRepository";
import { AxiosCampaignRepository } from "../../../core/campaigns/infrastructure/repository/AxiosCampaignRepository";
//import { CampaignDetailGetter } from '../../../core/campaigns/application/use_cases/getCampaignDetail/CampaignDetailtGetter'
import { toastr } from "react-redux-toastr";
import { CampaignCreator } from "../../../core/campaigns/application/use_cases/CampaignCreator";
import { BuyerPersonCreator } from "../../../core/campaigns/application/use_cases/BuyerPersonCreator";
import { AllCampaignsGetter } from "../../../core/campaigns/application/use_cases/AllCampaignsGetter";
import { CampaignModifier } from "../../../core/campaigns/application/use_cases/CampaignModifier";
import { CampaignDestroyer } from "../../../core/campaigns/application/use_cases/CampaignDestroyer";
import Channel from "../../../core/campaigns/domain/Channel";

const campaignRepository: CampaignRepository = new AxiosCampaignRepository();
const indexCampaigns = new AllCampaignsGetter(campaignRepository);
const createCampaign = new CampaignCreator(campaignRepository);
const createBuyerPerson = new BuyerPersonCreator(campaignRepository);
const campaignModifier = new CampaignModifier(campaignRepository);
const campaignDestroyer = new CampaignDestroyer(campaignRepository);

export const addCampaign = (
  name: string,
  age_range: [],
  target: [], // user id of owner, person that added the campaign
  brand_type: string,
  geolocalization: [],
  interest_topics: [],
  genders: [],
  price: Number,
  owner_id: string, // user id of owner, person that added the campaign
  start_date: Date,
  end_date: Date,
  channel: Channel[],
  starred: Boolean,
  deleted: Boolean,
  isActive: Boolean,
  payments: string[]
) => {
  let newBuyerPerson = {
    _id: undefined,
    age_range: age_range ? age_range : [],
    target: target ? target : [],
    brand_type: brand_type ? brand_type : "",
    geolocalization: geolocalization ? geolocalization : [],
    interest_topics: interest_topics ? interest_topics : [],
    genders: genders ? genders : [],
  };

  return (dispatch) => {
    return createBuyerPerson
      .run(newBuyerPerson)
      .then((res) => {
        toastr.success("Se ha guardado el cliente ideal");
        let newCampaign = {
          _id: undefined,
          name: name ? name : "",
          price: price ? Number(price) : 0,
          owner_id: owner_id ? owner_id : "",
          start_date: start_date ? start_date : new Date(Date.now()),
          end_date: end_date ? end_date : new Date(Date.now()),
          channel: channel ? channel : [],
          starred: false,
          deleted: false,
          isActive: false,
          owner: res ? res._id : "",
          payments: payments ? payments : [],
        };
        createCampaign
          .run(newCampaign)
          .then((res) => {
            toastr.success("Se ha guardado la campaña");
            dispatch({
              type: "ADD_CAMPAIGN",
              _id: res._id,
              name: res.name,
              price: res.price,
              owner_id: res.owner_id,
              start_date: res.start_date,
              end_date: res.end_date,
              channel: res.channel,
              starred: res.starred,
              deleted: res.deleted,
              isActive: res.isActive,
              owner: res.owner,
              payments: res.payments,
            });
            //window.location.reload();
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Hubo un problema");
            dispatch({
              type: "ADD_CAMPAIGN",
              name: name ? name : "",
              price: price ? price : 0,
              owner_id: owner_id ? owner_id : "",
              start_date: start_date ? start_date : 0,
              end_date: end_date ? end_date : "",
              channel: channel ? channel : "",
              starred: false,
              deleted: false,
              isActive: false,
              owner: "",
              payments: [],
            });
          });
      })
      .catch((error) => {
        console.log(error);
        toastr.error("Hubo un problema");
        dispatch({
          type: "ADD_BUYER_PERSON",
          target: target ? target : [],
          brand_type: brand_type ? brand_type : "",
          geolocalization: geolocalization ? geolocalization : [],
          interest_topics: interest_topics ? interest_topics : [],
          genders: genders ? genders : [],
        });
      });
  };
};

export const addBuyerPerson = (
  _id: string,
  age_range: [],
  target: [], // user id of owner, person that added the campaign
  brand_type: string,
  geolocalization: [],
  interest_topics: [],
  genders: []
) => {
  let newBuyerPerson = {
    _id: undefined,
    age_range: age_range ? age_range : [],
    target: target ? target : [],
    brand_type: brand_type ? brand_type : "",
    geolocalization: geolocalization ? geolocalization : [],
    interest_topics: interest_topics ? interest_topics : [],
    genders: genders ? genders : [],
  };

  return (dispatch) => {
    return createBuyerPerson
      .run(newBuyerPerson)
      .then((res) => {
        toastr.success("Se ha guardado el cliente ideal");
        dispatch({
          type: "ADD_BUYER_PERSON",
          _id: res._id,
          age_range: res.age_range,
          target: res.target,
          brand_type: res.brand_type,
          geolocalization: res.geolocalization,
          interest_topics: res.interest_topics,
          genders: res.genders,
        });

        //   window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        toastr.error("Hubo un problema");
        dispatch({
          type: "ADD_BUYER_PERSON",
          _id: _id ? _id : "",
          target: target ? target : [],
          brand_type: brand_type ? brand_type : "",
          geolocalization: geolocalization ? geolocalization : [],
          interest_topics: interest_topics ? interest_topics : [],
          genders: genders ? genders : [],
        });
      });
  };
};

export const updateCampaign = (_id, field, value) => {
  const updateCampaignReq = {
    _id: _id,
    field: field,
    value: value,
  };

  return (dispatch) => {
    return campaignModifier
      .run(updateCampaignReq)
      .then((res) => {
        toastr.success("La campaña se ha sido actualizado");
        dispatch({
          type: "UPDATE_CAMPAIGN",
          _id: _id,
          field: field,
          value: value,
        });
      })
      .catch((err) => {
        toastr.error("No se puedo actualizar el contenido");
        console.log(err);
      });
  };
};

export const campaignDetails = (_id) => {
  return function (dispatch) {
    dispatch({
      type: "CAMPAIGN_DETAILS",
      _id,
    });
  };
};

export const setEditCampaignFlag = (flag) => ({
  type: "EDIT_CAMPAIGN",
  flag,
});

export const setCampaignVisibilityFilter = (filter) => ({
  type: "SET_VISIBILITY_FILTER",
  filter,
});

export const campaignSearch = (searchTerm) => ({
  type: "FILTER_CAMPAIGN",
  payload: searchTerm,
});

export const toggleStarredCampaign = (_id) => ({
  type: "TOGGLE_STARRED_CAMPAIGN",
  _id,
});

export const deleteCampaign = (_id) => ({
  type: "DELETE_CAMPAIGN",
  _id,
});

export const destroyCampaign = (_id) => {
  return function (dispatch) {
    return campaignDestroyer
      .run(_id)
      .then((response) => {
        dispatch({
          type: "DESTROY_CAMPAIGN",
          _id,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "DESTROY_CAMPAIGN",
          _id,
        });
      });
  };
};

export function fetchCampaigns() {
  return function (dispatch) {
    return indexCampaigns
      .run()
      .then((data) => {
        dispatch({
          type: "GET_ALL_CAMPAIGNS",
          campaigns: data,
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        let errorMessage = "No se pudo obtener la lista de campaigns";
        if (err.data) {
          console.log("HAY ERROR DATA");
          console.log(err);
          errorMessage = err.status + " " + err.statusText;
        } else {
          if (err.message) {
            console.log("HAY ERROR MESSAGE");
            errorMessage = err.message;
          } else {
            console.log("ERROR GENERICO INDEX");
          }
        }
        toastr.error(errorMessage);
        dispatch({
          type: "GET_ALL_CAMPAIGNS",
          campaigns: [],
        });
      });
  };
}

export const campaignVisibilityFilter = {
  SHOW_ALL: "SHOW_ALL",
  DELETED_CAMPAIGN: "DELETED_CAMPAIGN",
  STARRED_CAMPAIGN: "STARRED_CAMPAIGN",
  ACTIVE_CAMPAIGN: "ACTIVE_CAMPAIGN",
  NOT_ACTIVE_CAMPAIGN: "NOT_ACTIVE_CAMPAIGN",
  SHOW_CAMPAIGN: "SHOW_CAMPAIGN",
  OTHER_CAMPAIGN: "OTHER_CAMPAIGN",
};
