import Receipt from "./Receipt";

export default class DomainPayment {
  _id: string;
  amount: Number;
  amountBs: Number;
  amountUsd: Number;
  amountEuro: Number;
  amountCrypto: Number;
  amountOther: Number;
  owner_id: string; // user id of owner, person that added the payment
  show_owner: Number; //show id if payment belongs to a show
  recording_turn_owner: string;
  concept: string;
  receipts: Receipt[];
  payment_date: Date;
  starred: Boolean;
  deleted: Boolean;
  reconciled: Boolean;
  currency: string;
  method: string;
  payment_type: string;

  constructor(
    _id: string,
    amount: Number,
    amountBs: Number,
    amountUsd: Number,
    amountEuro: Number,
    amountCrypto: Number,
    amountOther: Number,
    owner_id: string, // user id of owner
    show_owner: Number,
    recording_turn_owner: string,
    concept: string,
    receipts: Receipt[],
    payment_date: Date,
    starred: Boolean,
    deleted: Boolean,
    reconciled: Boolean,
    currency: string,
    method: string,
    payment_type: string
  ) {
    this._id = _id;
    this.amount = amount;
    this.amountBs = amountBs;
    this.amountUsd = amountUsd;
    this.amountEuro = amountEuro;
    this.amountCrypto = amountCrypto;
    this.amountOther = amountOther;
    this.concept = concept;
    this.owner_id = owner_id;
    this.recording_turn_owner = recording_turn_owner;
    this.show_owner = show_owner;
    this.payment_date = payment_date;
    this.receipts = receipts;
    this.starred = starred;
    this.deleted = deleted;
    this.reconciled = reconciled;
    this.currency = currency;
    this.method = method;
    this.payment_type = payment_type;
  }

  static create(
    _id: string,
    amount: Number,
    amountBs: Number,
    amountUsd: Number,
    amountEuro: Number,
    amountCrypto: Number,
    amountOther: Number,
    owner_id: string, // user id of owner
    show_owner: Number,
    recording_turn_owner: string,
    concept: string,
    receipts: Receipt[],
    payment_date: Date,
    starred: Boolean,
    deleted: Boolean,
    reconciled: Boolean,
    currency: string,
    method: string,
    payment_type: string
  ) {
    return new DomainPayment(
      _id,
      amount,
      amountBs,
      amountUsd,
      amountEuro,
      amountCrypto,
      amountOther,
      owner_id,
      show_owner,
      recording_turn_owner,
      concept,
      receipts,
      payment_date,
      starred,
      deleted,
      reconciled,
      currency,
      method,
      payment_type
    );
  }
}
