import Expense from "../../../domain/Expense";
import { ExpenseRepository } from "../../../domain/ExpenseRepository";

export class AllExpensesGetter {
  private repository: ExpenseRepository;

  constructor(repository: ExpenseRepository) {
    this.repository = repository;
  }

  async run(): Promise<Expense[] | Error> {
    return await this.repository.index();
  }
}
