const ExpenseDetails = (state = "", action) => {
  switch (action.type) {
    case "EXPENSE_DETAILS":
      return action._id;
    default:
      return state;
  }
};

export default ExpenseDetails;
