import { toastr } from "react-redux-toastr";
import { ClientDestroyer } from "../../../core/clients/application/use_cases/deleteClient/ClientDestroyer";
import { AllClientsGetter } from "../../../core/clients/application/use_cases/getClients/AllClientsGetter";
import { ClientIsUserStatusModifier } from "../../../core/clients/application/use_cases/modifyClient/ClientIsUserStatusModifier";
import { ClientModifier } from "../../../core/clients/application/use_cases/modifyClient/ClientModifier";
import { ClientRepository } from "../../../core/clients/domain/ClientRepository";
import { AxiosClientRepository } from "../../../core/clients/infrastructure/repository/AxiosClientRepository";
import { GetAgency } from "../../../core/clients/application/use_cases/getAgency/GetAgency";

const clientRepository: ClientRepository = new AxiosClientRepository();
const indexClients = new AllClientsGetter(clientRepository);
const clientModifier = new ClientModifier(clientRepository);
const clientDestroyer = new ClientDestroyer(clientRepository);
const clientIsUserModifier = new ClientIsUserStatusModifier(clientRepository);
const indexAgencies = new GetAgency(clientRepository);

export const updateClient = (id, field, value) => {
  const updateRequest = {
    id: id,
    field: field,
    value: value,
  };

  return (dispatch) => {
    return clientModifier
      .run(updateRequest)
      .then((res) => {
        toastr.success("El cliente ha sido actualizado");
        dispatch({
          type: "UPDATE_CLIENT",
          id: id,
          field: field,
          value: value,
        });
      })
      .catch((err) => {
        //toastr.error("No se puedo actualizar la pauta");
        console.log(err);
      });
  };
};

export const updateClientFc = async (_id, field, value) => {
  const updateRequest = {
    _id: _id,
    field: field,
    value: value,
  };

  try {
    const res = await clientModifier.run(updateRequest);
    toastr.success("El cliente ha sido actualizado");
    return res;
  } catch (error) {
    //toastr.error("No se puedo actualizar la pauta");
    console.log(error);
  }
};

export const updateIsUserClient = (id) => {
  return async (dispatch) => {
    try {
      const res = await clientIsUserModifier.run(id);
      toastr.success("El cliente ha sido actualizado");
      dispatch({
        type: "UPDATE_IS_USER_CLIENT",
        id: id,
      });
    } catch (error) {
      //toastr.error("No se puedo actualizar el cliente");
      console.log(error);
    }
  };
};

export const clientDetails = (_id) => {
  return function (dispatch) {
    dispatch({
      type: "CLIENT_DETAILS",
      _id,
    });
  };
};

export const agencyDetails = (_id) => {
  return function (dispatch) {
    dispatch({
      type: "AGENCY_DETAILS",
      _id,
    });
  };
};

export const setEditClientFlag = (flag) => ({
  type: "EDIT_CLIENT",
  flag,
});

export const setClientVisibilityFilter = (filter) => ({
  type: "SET_VISIBILITY_FILTER",
  filter,
});

export const clientSearch = (searchTerm) => ({
  type: "FILTER_CLIENT",
  payload: searchTerm,
});

export const toggleStarredClient = (_id) => ({
  type: "TOGGLE_STARRED_CLIENT",
  _id,
});

export const toggleSuspendClient = (_id) => ({
  type: "TOGGLE_SUSPEND_CLIENT",
  _id,
});

export const deleteClient = (_id) => ({
  type: "DELETE_CLIENT",
  _id,
});

export const destroyClient = (_id) => {
  return function (dispatch) {
    return clientDestroyer
      .run(_id)
      .then((response) => {
        dispatch({
          type: "DESTROY_CLIENT",
          _id,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "DESTROY_CLIENT",
          _id,
        });
      });
  };
};

export function fetchClients() {
  return function (dispatch) {
    return indexClients
      .run()
      .then((data) => {
        dispatch({
          type: "GET_ALL_CLIENTS",
          clients: data,
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        dispatch({
          type: "GET_ALL_CLIENTS",
          clients: [],
        });
      });
  };
}

export function fetchAgencies() {
  return function (dispatch) {
    return indexAgencies
      .run()
      .then((data) => {
        dispatch({
          type: "GET_ALL_AGENCIES",
          agencies: data,
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        let errorMessage = "No se pudo obtener la lista de AGENCIAS";
        if (err.data) {
          console.log("HAY ERROR DATA");
          console.log(err);
          errorMessage = err.status + " " + err.statusText;
        } else {
          if (err.message) {
            console.log("HAY ERROR MESSAGE");
            errorMessage = err.message;
          } else {
            console.log("ERROR GENERICO INDEX");
          }
        }
        toastr.error(errorMessage);
        dispatch({
          type: "GET_ALL_AGENCIES",
          agencies: [],
        });
      });
  };
}

export const clientVisibilityFilter = {
  SHOW_ALL: "SHOW_ALL",
  POTENTIAL_ADVERTISER: "POTENTIAL_ADVERTISER",
  POTENTIAL_PRODUCER: "POTENTIAL_PRODUCER",
  AGENCIES: "AGENCIES",
  IS_CLIENT_USER: "IS_CLIENT_USER",
};
