// Event Action Types
export const ADD_EVENT = "ADD_EVENT";

// Chat Action Types
export const HANDLE_FILTER_USER = "HANDLE_FILTER_USER";
export const HANDLE_SELECT_USER = "HANDLE_SELECT_USER";
export const HANDLE_SEND_MESSAGE = "HANDLE_SEND_MESSAGE";

//User Action Types
export const LOGIN_ACTION_KEY = "LOGIN_ACTION_KEY";
export const GET_USER_IMAGE = "GET_USER_IMAGE";

//Repository Action Types
export const INF_REPOSITORY_KEY = "INF_REPOSITORY_KEY";
