const TodoDetails = (state = "", action) => {
  //console.log(action);
  switch (action.type) {
    case "TODO_DETAILS":
      return action._id;
    default:
      return state;
  }
};

export default TodoDetails;
