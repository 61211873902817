import axios from "axios";
import RecordingTurn from "../../domain/RecordingTurn";
import { RecordingTurnRepository } from "../../domain/RecordingTurnRepository";
import { apiUrl } from "../../../../config";

//const baseURL = `${apiUrl}recordingturns/`;
const baseURL = "http://127.0.0.1:11000/api/recordingturns/";

export class AxiosRecordingTurnRepository implements RecordingTurnRepository {
  public async delete(_id: string): Promise<RecordingTurn> {
    try {
      const response = await axios.delete(`${baseURL}delete/${_id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public async index(): Promise<RecordingTurn[]> {
    try {
      const response = await axios.get(`${baseURL}index`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async save(recordingTurn: RecordingTurn): Promise<RecordingTurn> {
    console.log(recordingTurn);

    try {
      const response = await axios.post(`${baseURL}save`, recordingTurn);
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      //  console.error(error);
      if (error.response) {
        console.error(error.response);
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async update(updateRequest: any): Promise<RecordingTurn> {
    try {
      const result = await axios.patch(
        `${baseURL}update/${updateRequest._id}`,
        updateRequest
      );
      return Promise.resolve(result.data);
    } catch (error) {
      // console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async destroy(id: string): Promise<RecordingTurn> {
    try {
      const response = await axios.delete(`${baseURL}destroy/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getRecordingTurnById(_id: string): Promise<RecordingTurn> {
    let res;
    try {
      res = await axios.get(`${baseURL}details/${_id}/`);
      return res.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  async getRecordingTurnsPage(
    pageSize: number,
    page: number
  ): Promise<RecordingTurn[]> {
    try {
      const response = await axios.get(`${baseURL}index/${pageSize}/${page}`);
      //console.log(response);
      return response.data;
    } catch (error) {
      // console.log(error);
      if (error.response) {
        //console.log("ERROR.RESPONSE");
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          // console.log("ERROR OBJECT " + errorObject);
          return Promise.reject(errorObject);
        } else {
          //  console.log("ERROR GENERICO ");
          return Promise.reject(error);
        }
      }
    }
  }
}
