import { ClientRepository } from "../../../domain/ClientRepository";
//import { DeleteUserRequest } from './DeleteUserRequest'
import Client from "../../../domain/Client";

export class ClientDestroyer {
  private repository: ClientRepository;

  constructor(repository: ClientRepository) {
    this.repository = repository;
  }

  async run(id: string): Promise<Client | Error> {
    //ALGO EXTRAÑO SUCEDE, EL REQUEST RETORNA DIRECTO EL ID SIN
    //ENTRAR AL CAMPO
    try {
      console.log(id);

      return await this.repository.destroy(id);
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
