import { combineReducers } from "redux";
import getAllTopicMetricsNaiveBayesReducer from "./getAllTopicMetricsNaiveBayesReducer";
import GetAllPublishedFacebookReducer from "./getPublishedFacebookReducer";
import GetAllTasksProgressReducer from "./getTasksProgressReducer";
import GetAllTasksProgressPerPersonReducer from "./getTasksProgressPerPersonReducer";
import GetAllPublishedInstagramReducer from "./getPublishedInstagramReducer";
import GetUsersClassifiedByTopicReducer from "./getUsersClassifiedByTopicReducer";
import fetchUsersReducer from "./fetchUsersReducer";

export default combineReducers({
  getAllTopicMetricsNaiveBayesReducer,
  GetAllPublishedFacebookReducer,
  GetAllTasksProgressReducer,
  GetAllTasksProgressPerPersonReducer,
  GetAllPublishedInstagramReducer,
  fetchUsersReducer,
  GetUsersClassifiedByTopicReducer,
});
