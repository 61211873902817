import EmployeeTurn from "../../../domain/EmployeeTurn";
import { UserRepository } from "../../../domain/UserRepository";

export class EmployeeTurnModifier {
  private repository: UserRepository;

  constructor(repository: UserRepository) {
    this.repository = repository;
  }

  async run(updateValues): Promise<EmployeeTurn> {
    return await this.repository.updateEmployeeTurn(updateValues);
  }
}
