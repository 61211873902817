import Payment from "../../../domain/Payment";
import { PaymentRequest } from "../../PaymentRequest";
import { PaymentRepository } from "../../../domain/PaymentRepository";

export class PaymentCreator {
  private repository: PaymentRepository;

  constructor(repository: PaymentRepository) {
    this.repository = repository;
  }

  async run(
    request: PaymentRequest,
    files: [],
    showName: string
  ): Promise<Payment> {
    const payment = Payment.create(
      request._id,
      request.amount,
      request.amountBs,
      request.amountUsd,
      request.amountEuro,
      request.amountCrypto,
      request.amountOther,
      request.owner_id,
      request.show_owner,
      request.recording_turn_owner,
      request.concept,
      request.receipts,
      request.payment_date,
      request.starred,
      request.deleted,
      request.reconciled,
      request.currency,
      request.method,
      request.payment_type
    );

    return await this.repository.save(payment, files, showName);
  }
}
