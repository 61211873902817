import EmployeeTurn from "./EmployeeTurn";
import { UserEmail } from "./UserEmail";
import { UserImage } from "./UserImage";
import UserRole from "./UserRole";
import UserSocial from "./UserSocial";

export class User {
  _id: string;
  name: string;
  lastName: string;
  bio: string;
  birthdate: Date;
  image: string;
  dni: Number;
  email: UserEmail;
  isActive: Boolean;
  isWorking: Boolean;
  password: string;
  passwordConfirmation: string;
  socials: UserSocial[];
  gender: string;
  images: UserImage[];
  roles: UserRole[];
  address: string;
  notes: string;
  company: string;
  department: string;
  phone: string;
  isStarred: Boolean;
  isDeleted: Boolean;
  updatedBy: string;
  slug: string;
  turns: EmployeeTurn[];

  constructor(
    _id: string,
    name: string,
    lastName: string,
    bio: string,
    birthdate: Date,
    image: string,
    dni: Number,
    email: UserEmail,
    isActive: Boolean,
    isWorking: Boolean,
    password: string,
    passwordConfirmation: string,
    socials: UserSocial[],
    gender: string,
    images: UserImage[],
    roles: UserRole[],
    address: string,
    notes: string,
    company: string,
    department: string,
    phone: string,
    isStarred: Boolean,
    isDeleted: Boolean,
    updatedBy: string,
    slug: string,
    turns?: EmployeeTurn[]
  ) {
    this._id = _id;
    this.name = name;
    this.lastName = lastName;
    this.bio = bio;
    this.birthdate = birthdate;
    this.image = image;
    this.dni = dni;
    this.email = email;
    this.isActive = isActive;
    this.isWorking = isWorking;
    this.password = password;
    this.passwordConfirmation = passwordConfirmation;
    this.gender = gender;
    this.images = images;
    this.roles = roles;
    this.socials = socials;
    this.address = address;
    this.notes = notes;
    this.company = company;
    this.department = department;
    this.phone = phone;
    this.isStarred = isStarred;
    this.isDeleted = isDeleted;
    this.updatedBy = updatedBy;
    this.slug = slug;
    this.turns = turns;
  }

  static create(
    _id: string,
    name: string,
    lastName: string,
    bio: string,
    birthdate: Date,
    image: string,
    dni: Number,
    email: UserEmail,
    isActive: Boolean,
    isWorking: Boolean,
    password: string,
    passwordConfirmation: string,
    socials: UserSocial[],
    gender: string,
    images: UserImage[],
    roles: UserRole[],
    address: string,
    notes: string,
    company: string,
    department: string,
    phone: string,
    isStarred: Boolean,
    isDeleted: Boolean,
    updatedBy: string,
    slug: string,
    turns?: EmployeeTurn[]
  ) {
    return new User(
      _id,
      name,
      lastName,
      bio,
      birthdate,
      image,
      dni,
      email,
      isActive,
      isWorking,
      password,
      passwordConfirmation,
      socials,
      gender,
      images,
      roles,
      address,
      notes,
      company,
      department,
      phone,
      isStarred,
      isDeleted,
      updatedBy,
      slug,
      turns
    );
  }

  static createFromUser(user: any) {
    return new User(
      user._id,
      user.name,
      user.lastName,
      user.bio,
      user.birthdate,
      user.image,
      user.dni,
      user.email,
      user.isActive,
      user.isWorking,
      user.password,
      user.passwordConfirmation,
      user.socials,
      user.gender,
      user.images,
      user.roles,
      user.address,
      user.notes,
      user.company,
      user.department,
      user.phone,
      user.isStarred,
      user.isDeleted,
      user.updatedBy,
      user.slug,
      user.turns
    );
  }
}
