import BuyerPerson from "../../domain/BuyerPerson";
import { CampaignRepository } from "../../domain/CampaignRepository";
import BuyerPersonRequest from "./BuyerPersonRequest";

export class BuyerPersonCreator {
  private repository: CampaignRepository;

  constructor(repository: CampaignRepository) {
    this.repository = repository;
  }

  async run(request: BuyerPersonRequest): Promise<BuyerPerson> {
    const buyerPerson = BuyerPerson.create(
      request._id,
      request.age_range,
      request.target,
      request.brand_type,
      request.geolocalization,
      request.interest_topics,
      request.genders
    );

    return await this.repository.saveBuyerPerson(buyerPerson);
  }
}
