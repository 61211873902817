import { ToDo } from "../../../domain/ToDo";
import { ToDoRepository } from "../../../domain/ToDoRepository";
import { ToDoRequest } from "../../ToDoRequest";

export class ToDoCreator {
  private repository: ToDoRepository;

  constructor(repository: ToDoRepository) {
    this.repository = repository;
  }

  async run(request: ToDoRequest): Promise<ToDo> {
    const toDo = ToDo.create(
      request._id,
      request.toDo_id,
      request.full_name,
      request.task,
      request.owner_id,
      request.project,
      request.image,
      request.images,
      request.start_date,
      request.due_date,
      request.tags,
      request.comments,
      request.priority,
      request.department,
      request.starred,
      request.deleted,
      request.updatedBy,
      request.slug,
      request.completed,
      request.status
    );

    return await this.repository.save(toDo);
  }
}
