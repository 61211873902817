import Client from "../../../domain/Client";
import { ClientRepository } from "../../../domain/ClientRepository";
import { UpdateClientRequest } from "./UpdateClientRequest";

export class ClientIsUserStatusModifier {
  private repository: ClientRepository;
  request: UpdateClientRequest;

  constructor(repository: ClientRepository) {
    this.repository = repository;
  }

  async run(id): Promise<Client | Error> {
    return await this.repository.updateIsUserStatus(id);
  }
}
