import { combineReducers } from "redux";
import recordingTurns from "./recordingTurns";
import recordingTurnDetails from "./recordingTurnDetails";
import recordingTurnVisibilityFilter from "./recordingTurnVisibilityFilter";
import recordingTurnSearch from "./recordingTurnSearch";
import editRecordingTurn from "./editRecordingTurn";

export default combineReducers({
  recordingTurns,
  recordingTurnDetails,
  recordingTurnVisibilityFilter,
  recordingTurnSearch,
  editRecordingTurn,
});
