import { Repository } from "../../domain/Repository";

export class FileGetter {
  private repository: Repository;

  constructor(repository: Repository) {
    this.repository = repository;
  }

  async run(file_id: string, file_name: string): Promise<any> {
    return await this.repository.getFile(file_id, file_name);
  }
}
