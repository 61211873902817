let recordingTurnsList = [];

const recordingTurns = (state = recordingTurnsList, action) => {
  switch (action.type) {
    case "ADD_RECORDING_TURN":
      return [
        ...state,
        {
          _id: action._id,
          price: action.price,
          owner_id: action.owner_id,
          description: action.description,
          date: action.date,
          startTime: action.startTime,
          endTime: action.endTime,
          starred: false,
          deleted: false,
          status: "",
          payments: action.payments,
        },
      ];

    case "UPDATE_RECORDING_TURN":
      return state.map((recordingTurn) =>
        recordingTurn._id === action._id
          ? { ...recordingTurn, [action.field]: action.value }
          : recordingTurn
      );

    case "TOGGLE_RECORDING_TURN_STATUS":
      return state.map((recordingTurn) =>
        recordingTurn._id === action._id
          ? { ...recordingTurn, status: !recordingTurn.status }
          : recordingTurn
      );

    case "TOGGLE_STARRED_RECORDING_TURN":
      return state.map((recordingTurn) =>
        recordingTurn._id === action._id
          ? { ...recordingTurn, starred: !recordingTurn.starred }
          : recordingTurn
      );

    case "TOGGLE_SUSPEND_RECORDING_TURN":
      return state.map((recordingTurn) =>
        recordingTurn._id === action._id
          ? { ...recordingTurn, isSuspended: !recordingTurn.isSuspended }
          : recordingTurn
      );

    case "DESTROY_RECORDING_TURN":
      return state.map((recordingTurn) =>
        recordingTurn._id === action._id
          ? { ...recordingTurn, deleted: !recordingTurn.deleted }
          : recordingTurn
      );

    case "GET_ALL_RECORDING_TURNS":
      return action.recordingTurns;

    case "GET_USER_RECORDING_TURNS":
      return action.recordingTurns;

    default:
      return state;
  }
};

export default recordingTurns;
