import { combineReducers } from "redux";
import employeeTurn from "./employeeTurns";
import employeeTurnsVisibilityFilter from "./employeeTurnsVisibilityFilter";
import employeeTurnSearch from "./employeeTurnSearch";

export default combineReducers({
  employeeTurn,
  employeeTurnsVisibilityFilter,
  employeeTurnSearch,
});
