let initialState = [];

const showAdvertisers = (state = initialState, action) => {
  //console.log(state);
  switch (action.type) {
    case "GET_ALL_SHOW_ADVERTISERS":
      return action.showAdvertisers;
    case "UPDATE_SHOW_ADVERTISER":
      return state.map((advertiser) =>
        advertiser.id === action.id
          ? { ...advertiser, [action.field]: action.value }
          : advertiser
      );
    case "DELETE_SHOW_ADVERTISER":
      return state.filter((advertiser) => advertiser.id !== action.id);
    case "ADD_SHOW_ADVERTISER":
      //console.log("USERS FETCH USERS RED 4", action);
      if (action.message && action.message === "Se ha agregado con éxito") {
        //console.log("SE AGREGÓ");

        return [
          ...state,
          {
            id: action.id,
            name: action.name,
            website: action.website,
            image: action.image,
            showId: action.showId,
          },
        ];
      } else {
        //console.log("No SE AGREGO");
        //console.log(action);
        return [...state];
      }
    default:
      return state;
  }
};

export default showAdvertisers;
