import { Repository } from "../../domain/Repository";

export class FileCreator {
  private repository: Repository;

  constructor(repository: Repository) {
    this.repository = repository;
  }

  async run(payment, file, showName): Promise<any> {
    return await this.repository.createFile(payment, file, showName);
  }
}
