import { UserRepository } from "../../../domain/UserRepository";
//import { DeleteEmployeeTurnRequest } from './DeleteEmployeeTurnRequest'
import EmployeeTurn from "../../../domain/EmployeeTurn";

export class EmployeeTurnDestroyer {
  private repository: UserRepository;

  constructor(repository: UserRepository) {
    this.repository = repository;
  }

  async run(_id: string): Promise<EmployeeTurn | Error> {
    //ALGO EXTRAÑO SUCEDE, EL REQUEST RETORNA DIRECTO EL ID SIN
    //ENTRAR AL CAMPO
    try {
      console.log(_id);

      return await this.repository.destroyEmployeeTurn(_id);
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
