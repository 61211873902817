/**
 * Implements User repository interface with axios
 * promise based HTTP client.
 */

import { ToDo } from "../../domain/ToDo";
import { ToDoRepository } from "../../domain/ToDoRepository";
import axios from "axios";
import { apiUrl } from "../../../../config";
import Todo from "../../../../components/todo/todo";

const baseURL = `${apiUrl}toDos/`;

export class AxiosToDoRepository implements ToDoRepository {
  async getUserTodos(owner: string): Promise<ToDo[]> {
    console.log(owner);
    try {
      const response = await axios.get(`${baseURL}user/${owner}`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      if (error.response) {
        //console.log("ERROR.RESPONSE");
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          // console.log("ERROR OBJECT " + errorObject);
          return Promise.reject(errorObject);
        } else {
          //  console.log("ERROR GENERICO ");
          return Promise.reject(error);
        }
      }
    }
  }
  public async index(): Promise<ToDo[]> {
    try {
      const response = await axios.get(baseURL + "index");
      return response.data;
    } catch (error) {
      console.log(error);
      if (error.response) {
        //console.log("ERROR.RESPONSE");
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          // console.log("ERROR OBJECT " + errorObject);
          return Promise.reject(errorObject);
        } else {
          //  console.log("ERROR GENERICO ");
          return Promise.reject(error);
        }
      }
    }
  }

  public async save(toDo: ToDo): Promise<ToDo> {
    //    console.log(ToDo);
    try {
      const response = await axios.post(baseURL + "save", toDo);
      // console.log("RESPONSE ");
      console.log(response);
      if (response !== undefined && response.data !== undefined) {
        //  console.log("HAY ");
        /* return Promise.resolve(User.create(
                     response.data._id, response.data.name,
                     response.data.lastName, response.data.image,
                     response.data.dni, new UserEmail(response.data.email.value),
                     response.data.isActive, response.data.password,
                     response.data.passwordConfirmation,
                     response.data.gender, response.data.role,
                     response.data.address, response.data.notes,
                     response.data.company, response.data.department,
                     response.data.phone,
                     response.data.isDeleted, response.data.updatedBy));*/
        return response.data;
      } else {
        console.log("NO HAY ");
        return Promise.resolve(null);
      }
    } catch (error) {
      //  console.log(error);
      if (error.response) {
        console.log("ERROR.RESPONSE");
        console.log(error.response);
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          //console.log("ERROR OBJECT " + errorObject);
          return Promise.reject(errorObject);
        } else {
          //console.log("ERROR GENERICO ");
          return Promise.reject(error);
        }
      }
    }
  }

  public async updateToDo(updatedTodo: any): Promise<ToDo> {
    try {
      const result = await axios.patch(
        baseURL + "update/" + updatedTodo._id,
        updatedTodo
      );
      // console.log(result.data);
      return Promise.resolve(result.data);
    } catch (error) {
      // console.log(error);
      if (error.response) {
        //   console.log("ERROR.RESPONSE");
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          //console.log("ERROR OBJECT " + errorObject);
          return Promise.reject(errorObject);
        } else {
          //  console.log("ERROR GENERICO ");
          return Promise.reject(error);
        }
      }
    }
  }

  /* public async updateToDo(updateValues: any): Promise<ToDo> {
        //console.log("UPDATE AXIOS USER REPO ",updateValues);
        try {

            const response = await axios.get(url.URL_API_SHOWS + 'gettoDobyid/' + updateValues._id);
            //console.log(response);
            if (!response.data) {
                return Promise.reject(null);
            }
            else {
                /* let currentUserData = ToDo.createFromUser(response.data);
                 //  console.log(currentUserData);
                 const userToSend = {
                     _id: currentUserData._id,
                     name: updateValues.field === "name" ? updateValues.value : currentUserData.name,
                     lastName: updateValues.field === "lastName" ? updateValues.value : currentUserData.lastName,
                     image: updateValues.field === "image" ? updateValues.value : currentUserData.image,
                     dni: updateValues.field === "dni" ? updateValues.value : currentUserData.dni,
                     company: updateValues.field === "company" ? updateValues.value : currentUserData.company,
                     phone: updateValues.field === "phone" ? updateValues.value : currentUserData.phone,
                     email: updateValues.field === "email" ? updateValues.value : currentUserData.email,
                     isActive: updateValues.field === "isActive" ? updateValues.value : currentUserData.isActive,
                     address: updateValues.field === "address" ? updateValues.value : currentUserData.address,
                     notes: updateValues.field === "notes" ? updateValues.value : currentUserData.notes,
                     role: updateValues.field === "role" ? updateValues.value : currentUserData.role,
                     gender: updateValues.gender === "gender" ? updateValues.value : currentUserData.gender,
                     isDeleted: updateValues.field === "isDeleted" ? updateValues.value : currentUserData.isDeleted,
                     // updatedBy:  store.getState().session.user._id
                     updatedBy: ""
                 }
 
                 const result = await axios.patch('update/' + updateValues._id, userToSend);
                 // console.log(result.data);
                 return Promise.resolve(User.createFromUser(result.data));
            }

        } catch (error) {
            // console.log(error);
            if (error.response) {
                //   console.log("ERROR.RESPONSE");
                return Promise.reject(error.response);
            }
            else {
                let errorObject = JSON.parse(JSON.stringify(error));
                if (errorObject.message) {
                    //console.log("ERROR OBJECT " + errorObject);
                    return Promise.reject(errorObject);
                } else {
                    //  console.log("ERROR GENERICO ");
                    return Promise.reject(error);
                }
            }
        }
    }*/

  public async destroyToDo(id: string): Promise<ToDo> {
    //    console.log(_id);
    try {
      const response = await axios.delete(baseURL + "destroy/" + id);
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public async uploadImage(
    image,
    slug,
    location,
    name,
    toDoId,
    imageName
  ): Promise<any> {
    console.log(image);
    const formData = new FormData();
    formData.set("image", image, name);
    formData.set("slug", slug);
    formData.set("location", location);
    formData.set("name", imageName);
    formData.set("toDoId", toDoId);
    try {
      const response = await axios.post(baseURL + "uploadtodb", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  public async updateImageInDB(
    image,
    name,
    type,
    slug,
    location,
    imageId
  ): Promise<any> {
    console.log(image);
    console.log(name);
    const formData = new FormData();
    formData.set("image", image, image.name);
    formData.set("name", name);
    formData.set("type", type);
    formData.set("slug", slug);
    formData.set("location", location);
    formData.set("imageId", imageId);
    try {
      const response = await axios.patch(
        baseURL + "updateimageindb/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  public async getToDoImage(name, slug) {
    let res;
    try {
      //console.log(baseURL + 'details/' + slug);
      res = await axios.get(baseURL + "imagefromdb/" + name + "/" + slug);

      // console.log(res);

      return res.data;
    } catch (error) {
      console.log("ERROR");
      console.log(error);
      if (error.response) {
        //console.log("ERROR.RESPONSE");
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          // console.log("ERROR OBJECT " + errorObject);
          return Promise.reject(errorObject);
        } else {
          //  console.log("ERROR GENERICO ");
          return Promise.reject(error);
        }
      }
    }
  }

  public async getToDoDetail(slug) {
    let res;
    try {
      res = await axios.get(baseURL + "details/" + slug + "/");
      return res.data;
    } catch (error) {
      console.log("ERROR GET DETAIL");
      console.log(error);
      console.log("ERROR GET DETAIL");
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }
}
