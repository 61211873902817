let employeeTurnsState = {
  employeeTurn: {},
  employeeTurnsList: [],
};

const employeeTurnsReducer = (state = employeeTurnsState, action) => {
  switch (action.type) {
    case "ADD_EMPLOYEE_TURN":
      return { ...state, employeeTurn: action.employeeTurn };
    case "GET_EMPLOYEE_TURNS":
      return {
        ...state,
        employeeTurnsList: action.employeeTurns,
      };
    case "UPDATE_EMPLOYEE_TURN":
      return {
        ...state,
        employeeTurnsList: state.employeeTurnsList.map((employeeTurn) =>
          employeeTurn._id === action._id
            ? { ...employeeTurn, [action.field]: action.value }
            : employeeTurn
        ),
      };
    case "DESTROY_EMPLOYEE_TURN":
      return {
        ...state,
        employeeTurnsList: state.employeeTurnsList.filter(
          (employeeTurn) => employeeTurn._id !== action._id
        ),
      };
    default:
      return state;
  }
};

export default employeeTurnsReducer;
