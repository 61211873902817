const PaymentDetails = (state = "", action) => {
  switch (action.type) {
    case "PAYMENT_DETAILS":
      return action._id;
    default:
      return state;
  }
};

export default PaymentDetails;
