import { ExpenseRepository } from "../../../domain/ExpenseRepository";
//import { DeleteUserRequest } from './DeleteUserRequest'
import Expense from "../../../domain/Expense";

export class ExpenseDestroyer {
  private repository: ExpenseRepository;

  constructor(repository: ExpenseRepository) {
    this.repository = repository;
  }

  async run(_id: string): Promise<Expense | Error> {
    //ALGO EXTRAÑO SUCEDE, EL REQUEST RETORNA DIRECTO EL ID SIN
    //ENTRAR AL CAMPO
    try {
      console.log(_id);

      return await this.repository.destroy(_id);
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
