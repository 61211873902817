import { combineReducers } from "redux";
import posts from "./post";
import postDetails from "./postDetails";
import postVisibilityFilter from "./postVisibilityFilter";
import postSearch from "./postSearch";
import editPost from "./editPost";
import postTags from "./tag";
import postThemes from "./postThemes";
import postsCount from "./postCount";

export default combineReducers({
  posts,
  postDetails,
  postVisibilityFilter,
  postSearch,
  editPost,
  postTags,
  postThemes,
  postsCount,
});
