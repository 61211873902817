import { ToDoRepository } from "../../../domain/ToDoRepository";
import { ToDo } from "../../../domain/ToDo";

export class AllToDosGetter {
  private repository: ToDoRepository;

  constructor(repository: ToDoRepository) {
    this.repository = repository;
  }

  async run(): Promise<ToDo[]> {
    return await this.repository.index();
  }
}
