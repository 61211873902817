import { UserRepository } from "../../../domain/UserRepository";
import EmployeeTurn from "../../../domain/EmployeeTurn";

export class EmployeeTurnsGetter {
  private repository: UserRepository;

  constructor(repository: UserRepository) {
    this.repository = repository;
  }

  async run(userId: string): Promise<EmployeeTurn[]> {
    return await this.repository.getEmployeeTurns(userId);
  }
}
