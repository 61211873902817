const postsCount = (state = 0, action) => {
  //console.log(action);
  switch (action.type) {
    case "GET_POSTS_COUNT":
      return action.postsCount;
    default:
      return state;
  }
};

export default postsCount;
