import { LOGIN_ACTION_KEY } from "../../types/types";
const initial_state = {
  sessionUserDetails: {},
};

/**
 * The reducer function returns a new state which overwrites
 * the previous state in the redux store.
 * The action payload contains data to be added to the store
 */
const loginReducer = (state = initial_state, action) => {
  switch (action.type) {
    case LOGIN_ACTION_KEY:
      return { ...state, sessionUserDetails: { ...action.payload } };
    default:
      return state;
  }
};

export default loginReducer;
