const EditCampaign = (state = false, action) => {
  switch (action.type) {
    case "EDIT_CAMPAIGN":
      return !state ? true : false;
    case "CAMPAIGN_DETAILS":
      return (state = false);
    default:
      return state;
  }
};

export default EditCampaign;
