import Payment from "../../../domain/Payment";
import { PaymentRepository } from "../../../domain/PaymentRepository";
import { UpdatePaymentRequest } from "./UpdatePaymentRequest";

export class PaymentModifier {
  private repository: PaymentRepository;
  request: UpdatePaymentRequest;

  constructor(repository: PaymentRepository) {
    this.repository = repository;
  }

  async run(
    request: UpdatePaymentRequest,
    field?: string,
    files?: File[]
  ): Promise<Payment | Error> {
    const paymentUpdate = Payment.create(
      request._id,
      request.amount,
      request.amountBs,
      request.amountUsd,
      request.amountEuro,
      request.amountCrypto,
      request.amountOther,
      request.owner_id,
      request.show_owner,
      request.recording_turn_owner,
      request.concept,
      request.receipts,
      request.payment_date,
      request.starred,
      request.deleted,
      request.reconciled,
      request.currency,
      request.method,
      request.payment_type
    );

    return await this.repository.update(paymentUpdate, field, files);
  }
}
