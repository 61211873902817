const initialState = {
  userImage: {},
  userProfileImage: {},
};

const fetchUserImage = (state = initialState, action) => {
  // console.log(action.userImage);
  switch (action.type) {
    case "GET_USER_IMAGE":
      return { ...state, userImage: action.userImage };
    case "GET_USER_PROFILE_IMAGE":
      return { ...state, userProfileImage: action.userProfileImage };
    default:
      return state;
  }
};

export default fetchUserImage;
