const initialState = {
  progress: [],
};

const GetAllTasksProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TASKS_PROGRESS":
      return {
        progress: action.progress,
      };
    default:
      return state;
  }
};

export default GetAllTasksProgressReducer;
