import { PaymentRepository } from "../../../core/payments/domain/PaymentRepository";
import { AxiosPaymentRepository } from "../../../core/payments/infrastructure/repository/AxiosPaymentRepository";
//import { PaymentDetailGetter } from '../../../core/payments/application/use_cases/getPaymentDetail/PaymentDetailtGetter'
import { toastr } from "react-redux-toastr";
import { PaymentCreator } from "../../../core/payments/application/use_cases/createPayment/PaymentCreator";
import { AllPaymentsGetter } from "../../../core/payments/application/use_cases/getPayments/AllPaymentsGetter";
import { PaymentModifier } from "../../../core/payments/application/use_cases/modifyPayment/PaymentModifier";
import { PaymentDestroyer } from "../../../core/payments/application/use_cases/deletePayment/PaymentDestroyer";
import { FileGetter } from "../../../core/shared/use_cases/getFile/fileGetter";
import { FileCreator } from "../../../core/shared/use_cases/createFile/fileCreator";
import { FileDeleter } from "../../../core/shared/use_cases/deletefile/fileDeleter";
import { updateRecordingTurnFc } from "../recordingTurns";

const paymentRepository: PaymentRepository = new AxiosPaymentRepository();
const indexPayments = new AllPaymentsGetter(paymentRepository);
const createPayment = new PaymentCreator(paymentRepository);
const paymentModifier = new PaymentModifier(paymentRepository);
const paymentDestroyer = new PaymentDestroyer(paymentRepository);
const fileGetter = new FileGetter(paymentRepository);
const fileCreator = new FileCreator(paymentRepository);
const fileDeleter = new FileDeleter(paymentRepository);

export const addPayment = (
  _id: string,
  amount: Number,
  amountBs: Number,
  amountUsd: Number,
  amountEuro: Number,
  amountCrypto: Number,
  amountOther: Number,
  owner_id: string, // user id of owner
  show_owner: number,
  recording_turn_owner: string,
  concept: string,
  receiptFiles: [],
  payment_date: Date,
  starred: Boolean,
  deleted: Boolean,
  reconciled: Boolean,
  currency: string,
  method: string,
  payment_type: string,
  showName: string,
  seed: any
) => {
  let newPayment = {
    _id: undefined,
    amount: amount ? Number(amount) : 0,
    amountBs: amountBs ? Number(amountBs) : 0,
    amountUsd: amountUsd ? Number(amountUsd) : 0,
    amountEuro: amountEuro ? Number(amountEuro) : 0,
    amountCrypto: amountCrypto ? Number(amountCrypto) : 0,
    amountOther: amountOther ? Number(amountOther) : 0,
    owner_id: owner_id ? owner_id : "",
    show_owner: show_owner ? show_owner : 0,
    recording_turn_owner: recording_turn_owner ? recording_turn_owner : "",
    concept: concept ? concept : "",
    receipts: [],
    payment_date: payment_date ? payment_date : new Date(Date.now()),
    starred: false,
    deleted: false,
    reconciled: false,
    currency: currency,
    method: method,
    payment_type: payment_type,
  };

  return (dispatch) => {
    return createPayment
      .run(newPayment, receiptFiles, showName)
      .then((res) => {
        toastr.success("Se ha guardado el pago");
        dispatch({
          type: "ADD_PAYMENT",
          _id: res._id,
          amount: res.amount,
          amountBs: res.amountBs,
          amountUsd: res.amountUsd,
          amountEuro: res.amountEuro,
          amountCrypto: res.amountCrypto,
          amountOther: res.amountOther,
          owner_id: res.owner_id,
          show_owner: res.show_owner,
          recording_turn_owner: res.recording_turn_owner,
          concept: res.concept,
          receipts: res.receipts,
          payment_date: res.payment_date,
          starred: res.starred,
          deleted: res.deleted,
          reconciled: res.reconciled,
          currency: res.currency,
          method: res.method,
          payment_type: res.payment_type,
        });
        seed();
        if (res.recording_turn_owner !== "") {
          updateRecordingTurnFc(res.recording_turn_owner, "payments", res._id)
            .then(() =>
              dispatch({
                type: "UPDATE_RECORDING_TURN",
                _id: res.recording_turn_owner,
                field: "payments",
                value: res._id,
              })
            )
            .catch((e) => {
              console.log(e);
            });
        }
        //window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        toastr.error("Hubo un problema");
        dispatch({
          type: "ADD_PAYMENT",
          _id: _id ? _id : "",
          amount: amount ? amount : 0,
          amountBs: amountBs ? amountBs : 0,
          amountUsd: amountUsd ? amountUsd : 0,
          amountEuro: amountEuro ? amountEuro : 0,
          amountCrypto: amountCrypto ? amountCrypto : 0,
          amountOther: amountOther ? amountOther : 0,
          owner_id: owner_id ? owner_id : "",
          show_owner: show_owner ? show_owner : 0,
          recording_turn_owner: recording_turn_owner
            ? recording_turn_owner
            : "",
          concept: concept ? concept : "",
          receipts: [],
          payment_date: new Date(Date.now()),
          starred: false,
          approved: false,
          deleted: false,
          reconciled: false,
          currency: "",
          method: "",
          payment_type: "",
        });
      });
  };
};

export const updatePayment = (_id, field, value, selectedPayment, files) => {
  const updatedPayment = {
    _id: _id,
    amount: field === "amount" ? value : selectedPayment.amount,
    amountBs: field === "amountBs" ? value : selectedPayment.amountBs,
    amountUsd: field === "amountUsd" ? value : selectedPayment.amountUsd,
    amountEuro: field === "amountEuro" ? value : selectedPayment.amountEuro,
    amountCrypto:
      field === "amountCrypto" ? value : selectedPayment.amountCrypto,
    amountOther: field === "amountOther" ? value : selectedPayment.amountOther,
    owner_id: field === "owner_id" ? value : selectedPayment.owner_id,
    publish_date:
      field === "publish_date" ? value : selectedPayment.publish_date,
    show_owner: field === "show_owner" ? value : selectedPayment.show_owner,
    recording_turn_owner:
      field === "recording_turn_owner"
        ? value
        : selectedPayment.recording_turn_owner,
    concept: field === "concept" ? value : selectedPayment.concept,
    receipts: field === "receipts" ? value : selectedPayment.receipts,
    payment_date:
      field === "payment_date" ? value : selectedPayment.payment_date,
    starred: field === "starred" ? value : selectedPayment.starred,
    deleted: field === "deleted" ? value : selectedPayment.deleted,
    reconciled: field === "reconciled" ? value : selectedPayment.reconciled,
    currency: field === "currency" ? value : selectedPayment.currency,
    method: field === "method" ? value : selectedPayment.method,
    payment_type:
      field === "payment_type" ? value : selectedPayment.payment_type,
  };

  return (dispatch) => {
    return paymentModifier
      .run(updatedPayment, field, files)
      .then((res) => {
        toastr.success("El pago ha sido actualizado");
        dispatch({
          type: "UPDATE_PAYMENT",
          _id: _id,
          field: field,
          value: value,
        });
      })
      .catch((err) => {
        toastr.error("No se puedo actualizar el contenido");
        console.log(err);
      });
  };
};

export const paymentDetails = (_id) => {
  return function (dispatch) {
    dispatch({
      type: "PAYMENT_DETAILS",
      _id,
    });
  };
};

export const setEditPaymentFlag = (flag) => ({
  type: "EDIT_PAYMENT",
  flag,
});

export const setPaymentVisibilityFilter = (filter) => ({
  type: "SET_VISIBILITY_FILTER",
  filter,
});

export const paymentSearch = (searchTerm) => ({
  type: "FILTER_PAYMENT",
  payload: searchTerm,
});

export const toggleStarredPayment = (_id) => ({
  type: "TOGGLE_STARRED_PAYMENT",
  _id,
});

export const deletePayment = (_id) => ({
  type: "DELETE_PAYMENT",
  _id,
});

export const destroyPayment = (_id) => {
  return function (dispatch) {
    return paymentDestroyer
      .run(_id)
      .then((response) => {
        dispatch({
          type: "DESTROY_PAYMENT",
          _id,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "DESTROY_PAYMENT",
          _id,
        });
      });
  };
};

export const destroyFile = (file_id: string) => {
  return fileDeleter
    .run(file_id)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
};

export function fetchPayments() {
  return function (dispatch) {
    return indexPayments
      .run()
      .then((data) => {
        dispatch({
          type: "GET_ALL_PAYMENTS",
          payments: data,
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        let errorMessage = "No se pudo obtener la lista de payments";
        if (err.data) {
          console.log("HAY ERROR DATA");
          console.log(err);
          errorMessage = err.status + " " + err.statusText;
        } else {
          if (err.message) {
            console.log("HAY ERROR MESSAGE");
            errorMessage = err.message;
          } else {
            console.log("ERROR GENERICO INDEX");
          }
        }
        toastr.error(errorMessage);
        dispatch({
          type: "GET_ALL_PAYMENTS",
          payments: [],
        });
      });
  };
}

export const fetchReceiptFile = (file_id: string, file_name: string) => {
  return fileGetter
    .run(file_id, file_name)
    .then((data) => {
      console.log("data", data);
      return data;
    })
    .catch((err) => {
      console.log(err);
      let errorMessage = "No se pudo obtener el archivo";
      if (err.data) {
        errorMessage = err.data.error;
      } else {
        if (err.message) {
          errorMessage = err.message;
        } else {
        }
      }
      toastr.error(errorMessage);
      return null;
    });
};

export const uploadReceiptFile = (payment, file, showName) => {
  return fileCreator
    .run(payment, file, showName)
    .then((data) => {
      console.log("data", data);
      return data;
    })
    .catch((err) => {
      console.log(err);
      let errorMessage = "No se pudo obtener el archivo";
      if (err.data) {
        errorMessage = err.data.error;
      } else {
        if (err.message) {
          errorMessage = err.message;
        } else {
        }
      }
      toastr.error(errorMessage);
      return null;
    });
};

export const paymentVisibilityFilter = {
  SHOW_ALL: "SHOW_ALL",
  DELETED_PAYMENT: "DELETED_PAYMENT",
  STARRED_PAYMENT: "STARRED_PAYMENT",
  RECONCILED_PAYMENT: "RECONCILED_PAYMENT",
  NOT_RECONCILED_PAYMENT: "NOT_RECONCILED_PAYMENT",
  SHOW_PAYMENT: "SHOW_PAYMENT",
  OTHER_PAYMENT: "OTHER_PAYMENT",
  RECORDING_STUDIO_PAYMENT: "RECORDING_STUDIO_PAYMENT",
  POST_PROD_PAYMENT: "POST_PROD_PAYMENT",
  RECORDING_PAYMENT: "RECORDING_PAYMENT",
  USD_PAYMENT: "USD_PAYMENT",
  BS_PAYMENT: "BS_PAYMENT",
  EURO_PAYMENT: "EURO_PAYMENT",
  CRYPTO_PAYMENT: "CRYPTO_PAYMENT",
};
