import { PaymentRepository } from "../../../domain/PaymentRepository";
//import { DeleteUserRequest } from './DeleteUserRequest'
import Payment from "../../../domain/Payment";

export class PaymentDestroyer {
  private repository: PaymentRepository;

  constructor(repository: PaymentRepository) {
    this.repository = repository;
  }

  async run(_id: string): Promise<Payment | Error> {
    //ALGO EXTRAÑO SUCEDE, EL REQUEST RETORNA DIRECTO EL ID SIN
    //ENTRAR AL CAMPO
    try {
      console.log(_id);

      return await this.repository.destroy(_id);
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
