export default class BuyerPerson {
  _id: string;
  age_range: string[];
  target: string[];
  brand_type: string; //Product or Service
  geolocalization: string[]; // Cities, states, countries
  interest_topics: string[];
  genders: string[];

  constructor(
    _id: string,
    age_range: string[],
    target: string[],
    brand_type: string, //Product or Service
    geolocalization: string[], // Cities, states, countries
    interest_topics: string[],
    genders: string[]
  ) {
    this._id = _id;
    this.age_range = age_range;
    this.target = target;
    this.brand_type = brand_type;
    this.geolocalization = geolocalization;
    this.interest_topics = interest_topics;
    this.genders = genders;
  }

  static create(
    _id: string,
    age_range: string[],
    target: string[],
    brand_type: string, //Product or Service
    geolocalization: string[], // Cities, states, countries
    interest_topics: string[],
    genders: string[]
  ) {
    return new BuyerPerson(
      _id,
      age_range,
      target,
      brand_type,
      geolocalization,
      interest_topics,
      genders
    );
  }
}
