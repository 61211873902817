import { combineReducers } from "redux";
import chats from "./chats";
import chatSearch from "./chatSearch";
import chatContent from "./chatContent";
import {
  telegramAuthenticate,
  fetchTelegramMessages,
  messageHistory,
} from "./telegramReducer";

const chatReducer = combineReducers({
  chats,
  chatSearch,
  chatContent,
  telegramAuthenticate,
  fetchTelegramMessages,
  messageHistory,
});

export default chatReducer;
