const ShowDetails = (state = "", action) => {
  switch (action.type) {
    case "SHOW_DETAILS":
      return action.id;
    default:
      return state;
  }
};

export default ShowDetails;
