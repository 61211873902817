/**
 * Implements User repository interface with axios
 * promise based HTTP client.
 */

import { User } from "../../domain/User";
import { UserRepository } from "../../domain/UserRepository";
import axios from "axios";
import { UserEmail } from "../../domain/UserEmail";
import { UserSession } from "../../domain/UserSession";
import Notification from "../../domain/Notification";
import EmployeeTurn from "../../domain/EmployeeTurn";
import { usersUrl } from "../../../../config";

const baseURL = usersUrl;

export class AxiosUserRepository implements UserRepository {
  public async index(): Promise<User[]> {
    try {
      const response = await axios.get(`${baseURL}/index`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async resetLoggedInUserPassword(
    _id: string,
    oldPassword: string,
    password: string,
    passwordConfirmation: string
  ): Promise<User> {
    const request = {
      _id: _id,
      oldPassword: oldPassword,
      password: password,
      passwordConfirmation: passwordConfirmation,
    };
    try {
      const response = await axios.patch(
        `${baseURL}/resetloggeduserpass`,
        request
      );
      return response.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async saveUserNotification(
    notification: Notification
  ): Promise<Notification> {
    try {
      const response = await axios.post(
        `${baseURL}/notifications/save`,
        notification
      );
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        return error.response;
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return errorObject;
        } else {
          return error;
        }
      }
    }
  }

  public async getEmployeeTurns(userId: string): Promise<EmployeeTurn[]> {
    try {
      const response = await axios.get(`${baseURL}/employeeturns/${userId}`);
      console.log(response);
      if (response.data === null) {
        return null;
      } else {
        return response.data;
      }
    } catch (error) {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.error("ERROR ", error.response);
        throw Error(error.response.data);
      } else if (error.request) {
        // client never received a response, or request never left
        console.error("ERROR ", error.request);
      } else {
        // anything else
        throw Error(error);
      }
    }
  }

  public async saveEmployeeTurn(
    employeeTurn: EmployeeTurn
  ): Promise<EmployeeTurn> {
    try {
      const response = await axios.post(
        `${baseURL}/employeeturn/save`,
        employeeTurn
      );
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        return error.response;
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return errorObject;
        } else {
          return error;
        }
      }
    }
  }

  public async updateEmployeeTurn(updateValues: any): Promise<EmployeeTurn> {
    try {
      const response = await axios.patch(
        `${baseURL}/employeeturn/update/${updateValues._id}`,
        updateValues
      );
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        return error.response;
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return errorObject;
        } else {
          return error;
        }
      }
    }
  }
  public async updateNotificationStatus(
    updateValues: any
  ): Promise<Notification> {
    try {
      const response = await axios.patch(
        `${baseURL}/notifications/updatestatus/${updateValues._id}`,
        { _id: updateValues._id, isActive: updateValues.value }
      );
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        return error.response;
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return errorObject;
        } else {
          return error;
        }
      }
    }
  }

  public async save(user: User): Promise<User> {
    try {
      const response = await axios.post(`${baseURL}/save`, user);
      console.log(response);
      if (response !== undefined && response.data !== undefined) {
        return Promise.resolve(
          User.create(
            response.data._id,
            response.data.name,
            response.data.lastName,
            response.data.bio,
            response.data.birthdate,
            response.data.image,
            response.data.dni,
            new UserEmail(response.data.email.value),
            response.data.isActive,
            response.data.isWorking,
            response.data.password,
            response.data.passwordConfirmation,
            response.data.socials,
            response.data.gender,
            response.data.images,
            response.data.roles,
            response.data.address,
            response.data.notes,
            response.data.company,
            response.data.department,
            response.data.phone,
            response.data.isStarred,
            response.data.isDeleted,
            response.data.updatedBy,
            response.data.slug,
            response.data.turns
          )
        );
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async updateUser(updateValues: any): Promise<User> {
    try {
      const response = await axios.get(
        `${baseURL}/getuserbyid/${updateValues._id}`
      );
      if (!response.data) {
        console.log("Error getting user");
        return Promise.reject(null);
      } else {
        let currentUserData = User.createFromUser(response.data);
        const userToSend = {
          _id: currentUserData._id,
          name:
            updateValues.field === "name"
              ? updateValues.value
              : currentUserData.name,
          lastName:
            updateValues.field === "lastName"
              ? updateValues.value
              : currentUserData.lastName,
          bio:
            updateValues.field === "bio"
              ? updateValues.value
              : currentUserData.bio,
          birthdate:
            updateValues.field === "birthdate"
              ? updateValues.value
              : currentUserData.birthdate,
          image:
            updateValues.field === "image"
              ? updateValues.value
              : currentUserData.image,
          dni:
            updateValues.field === "dni"
              ? updateValues.value
              : currentUserData.dni,
          company:
            updateValues.field === "company"
              ? updateValues.value
              : currentUserData.company,
          department:
            updateValues.field === "department"
              ? updateValues.value
              : currentUserData.department,
          phone:
            updateValues.field === "phone"
              ? updateValues.value
              : currentUserData.phone,
          email:
            updateValues.field === "email"
              ? updateValues.value
              : currentUserData.email,
          isActive:
            updateValues.field === "isActive"
              ? updateValues.value
              : currentUserData.isActive,
          isWorking:
            updateValues.field === "isWorking"
              ? updateValues.value
              : currentUserData.isWorking,
          address:
            updateValues.field === "address"
              ? updateValues.value
              : currentUserData.address,
          notes:
            updateValues.field === "notes"
              ? updateValues.value
              : currentUserData.notes,
          roles:
            updateValues.field === "roles"
              ? updateValues.value
              : currentUserData.roles,
          socials:
            updateValues.field === "socials"
              ? updateValues.value
              : currentUserData.socials,
          gender:
            updateValues.gender === "gender"
              ? updateValues.value
              : currentUserData.gender,
          images:
            updateValues.images === "images"
              ? updateValues.value
              : currentUserData.images,
          isStarred:
            updateValues.field === "isStarred"
              ? updateValues.value
              : currentUserData.isStarred,
          isDeleted:
            updateValues.field === "isDeleted"
              ? updateValues.value
              : currentUserData.isDeleted,
          slug:
            updateValues.field === "slug"
              ? updateValues.value
              : currentUserData.slug,
          turns:
            updateValues.field === "turns"
              ? updateValues.value
              : currentUserData.turns,
          updatedBy: "",
        };

        const result = await axios.patch(
          `${baseURL}/update/${updateValues._id}`,
          userToSend
        );
        return Promise.resolve(User.createFromUser(result.data));
      }
    } catch (error) {
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async deleteUser(_id: string): Promise<User> {
    try {
      const response = await axios.patch("delete/" + _id);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async isValidEmail(email: string): Promise<boolean> {
    try {
      const response = await axios({
        url: "verifyemail",
        method: "get",
        params: { email: email.toString() },
      });

      if (response.data.isTaken === "Email taken") {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async getUserByEmail(email: UserEmail): Promise<User> {
    try {
      const response = await axios({
        url: "getuserbyemail",
        method: "get",
        params: { email: email },
      });

      if (response.data === null) {
        return null;
      } else {
        return response.data.user;
      }
    } catch (error) {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.error("ERROR ", error.response);
        throw Error(error.response.data);
      } else if (error.request) {
        // client never received a response, or request never left
        console.error("ERROR ", error.request);
      } else {
        // anything else
        throw Error(error);
      }
    }
  }

  public async getUserNotifications(userId: string): Promise<Notification[]> {
    try {
      const response = await axios.get(
        `${baseURL}/notifications/getbyuserid/${userId}`
      );
      //console.log(response);
      if (response.data === null) {
        return null;
      } else {
        return response.data;
      }
    } catch (error) {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.error("ERROR ", error.response);
        throw Error(error.response.data);
      } else if (error.request) {
        // client never received a response, or request never left
        console.error("ERROR ", error.request);
      } else {
        // anything else
        throw Error(error);
      }
    }
  }

  public async userLogin(
    email: UserEmail,
    password: string
  ): Promise<UserSession> {
    try {
      console.log(email);
      const response = await axios.post(`${baseURL}/login`, {
        email: email,
        password: password,
      });
      if (response.data.status === 400) {
        console.error("Status 400");
        return null;
      } else {
        let session = new UserSession(response.data.user, response.data.token);
        return session;
      }
    } catch (error) {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.error("ERROR ", error.response);
        throw Error(error.response.data);
      } else if (error.request) {
        // client never received a response, or request never left
        console.error("ERROR ", error.request);
      } else {
        // anything else
        throw Error(error);
      }
    }
  }

  async userLogout(userSession: UserSession): Promise<Boolean> {
    try {
      const response = await axios({
        url: "logout",
        method: "post",
        params: { user: userSession.user, token: userSession.token },
      });

      if (response.data === null) {
        return null;
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async uploadImage(image, name): Promise<any> {
    const formData = new FormData();
    formData.set("upload", image, name);
    formData.set("name", name);
    try {
      const response = await axios.post(`${baseURL}/upload/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  public async uploadImageToDB(
    image,
    name,
    type,
    slug,
    location,
    owner
  ): Promise<any> {
    const formData = new FormData();
    formData.set("image", image, name);
    formData.set("type", type);
    formData.set("slug", slug);
    formData.set("location", location);
    formData.set("owner", owner);
    try {
      const response = await axios.post(`${baseURL}/uploadtodb/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  public async updateImageInDB(
    image,
    name,
    type,
    slug,
    location,
    owner
  ): Promise<any> {
    const formData = new FormData();
    formData.set("image", image, name);
    formData.set("type", type);
    formData.set("slug", slug);
    formData.set("location", location);
    formData.set("owner", owner);
    try {
      const response = await axios.patch(
        `${baseURL}/updateimageindb/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  public async saveUserWithImages(user): Promise<any> {
    const formData = new FormData();
    formData.set("user", user);
    formData.set("profile", user.images[0].image);
    formData.set("talent", user.images[1].image);

    try {
      const response = await axios.post(`${baseURL}/uploadtodb`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  public async destroyUser(id: string): Promise<User> {
    try {
      const response = await axios.delete(`${baseURL}/destroy/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async destroyImage(userSlug: string): Promise<User> {
    try {
      const response = await axios.delete(
        `${baseURL}/destroyImage/${userSlug}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getUserImage(name, slug) {
    let res;
    try {
      res = await axios.get(`${baseURL}/imagefromdb/${name}/${slug}`);
      return res.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async sendEmail(
    email: string,
    subject: string,
    text: string
  ): Promise<string> {
    try {
      let res = await axios.get(
        `${baseURL}/sendemail/${email}/${subject}/${text}`
      );
      return res.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async destroyEmployeeTurn(id: string): Promise<EmployeeTurn> {
    try {
      const response = await axios.delete(
        `${baseURL}/employeeturn/destroy/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
