const initialState = [];

const showPGs = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SHOW_PG_CLASS":
      return [...action.showPGs];
    default:
      return state;
  }
};

export default showPGs;
