import DomainCampaign from "../../domain/Campaign";
import { CampaignRepository } from "../../domain/CampaignRepository";
import { UpdateCampaignRequest } from "../UpdateCampaignRequest";

export class CampaignModifier {
  private repository: CampaignRepository;
  request: UpdateCampaignRequest;

  constructor(repository: CampaignRepository) {
    this.repository = repository;
  }

  async run(request: UpdateCampaignRequest): Promise<DomainCampaign> {
    return await this.repository.update(request);
  }
}
