let campaignsList = [];

const campaigns = (state = campaignsList, action) => {
  switch (action.type) {
    case "ADD_CAMPAIGN":
      return [
        ...state,
        {
          _id: action._id,
          name: action.name,
          price: action.price,
          owner_id: action.owner_id,
          start_date: action.start_date,
          end_date: action.end_date,
          channel: action.channel,
          starred: false,
          deleted: false,
          isActive: false,
          owner: action.owner,
          payments: action.payments,
        },
      ];

    case "UPDATE_CAMPAIGN":
      return state.map((campaign) =>
        campaign._id === action._id
          ? { ...campaign, [action.field]: action.value }
          : campaign
      );

    case "TOGGLE_ACTIVATE_CAMPAIGN":
      return state.map((campaign) =>
        campaign._id === action._id
          ? { ...campaign, isActive: !campaign.isActive }
          : campaign
      );

    case "TOGGLE_STARRED_CAMPAIGN":
      return state.map((campaign) =>
        campaign._id === action._id
          ? { ...campaign, starred: !campaign.starred }
          : campaign
      );

    case "DESTROY_CAMPAIGN":
      return state.map((campaign) =>
        campaign._id === action._id
          ? { ...campaign, deleted: !campaign.deleted }
          : campaign
      );

    case "GET_ALL_CAMPAIGNS":
      return action.campaigns;

    case "GET_USER_CAMPAIGNS":
      return action.campaigns;

    default:
      return state;
  }
};

export default campaigns;
