let postsList = [];

const posts = (state = postsList, action) => {
  switch (action.type) {
    case "ADD_POST":
      return [
        ...state,
        {
          _id: action._id,
          title: action.title,
          image: action.image,
          subtitle: action.subtitle,
          publish_date: action.publish_date,
          summary: action.summary,
          project: action.project,
          images: action.images,
          text: action.text,
          tags: action.tags,
          isActive: true,
          approved: false,
          starred: false,
          deleted: false,
          slug: action.slug,
        },
      ];

    case "UPDATE_POST":
      return state.map((post) =>
        post._id === action._id
          ? { ...post, [action.field]: action.value }
          : post
      );

    case "UPDATE_SELECTED_POST":
      return state.map((post) =>
        post._id === action.selectedPost._id ? action.selectedPost : post
      );

    case "TOGGLE_APPROVE_POST":
      return state.map((post) =>
        post._id === action._id ? { ...post, approved: !post.approved } : post
      );

    case "TOGGLE_STARRED_POST":
      return state.map((post) =>
        post._id === action._id ? { ...post, starred: !post.starred } : post
      );

    case "DELETE_POST":
      return state.map((post) =>
        post._id === action._id ? { ...post, deleted: !post.deleted } : post
      );

    case "GET_ALL_POSTS":
      return action.posts;

    case "GET_POSTS_PAGE":
      return action.posts;
    default:
      return state;
  }
};

export default posts;
