import { StringValueObject } from "../../shared/domain/value-object/StringValueObject";
import { InvalidArgumentError } from "../../shared/domain/value-object/InvalidArgumentError";

export class UserEmail extends StringValueObject {
  constructor(value: string) {
    super(value);
    this.isValidEmail(value);
  }

  private isValidEmail(value: string): void {
    const regExp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regExp.test(String(value).toLowerCase()) === false) {
      throw new InvalidArgumentError(
        `El Email <${value}> no tiene el formato correcto`
      );
    }
  }
}
