import { campaignVisibilityFilter } from "../../actions/campaigns";

const CampaignVisibilityFilter = (
  state = campaignVisibilityFilter.SHOW_ALL,
  action
) => {
  switch (action.type) {
    case "SET_VISIBILITY_FILTER":
      return action.filter;
    default:
      return state;
  }
};

export default CampaignVisibilityFilter;
