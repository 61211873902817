import Campaign from "../../domain/Campaign";
import { CampaignRepository } from "../../domain/CampaignRepository";
import { CampaignRequest } from "../CampaignRequest";

export class CampaignCreator {
  private repository: CampaignRepository;

  constructor(repository: CampaignRepository) {
    this.repository = repository;
  }

  async run(request: CampaignRequest): Promise<Campaign> {
    const campaign = Campaign.create(
      request._id,
      request.name,
      request.price,
      request.owner_id,
      request.start_date,
      request.end_date,
      request.channel,
      request.starred,
      request.deleted,
      request.isActive,
      request.owner,
      request.payments
    );

    return await this.repository.save(campaign);
  }
}
