import { Repository } from "../../domain/Repository";

export class FileDeleter {
  private repository: Repository;

  constructor(repository: Repository) {
    this.repository = repository;
  }

  async run(fileId): Promise<any> {
    return await this.repository.deleteFile(fileId);
  }
}
