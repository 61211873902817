import DomainRecordingTurn from "../../domain/RecordingTurn";
import { RecordingTurnRepository } from "../../domain/RecordingTurnRepository";
import { RecordingTurnRequest } from "../RecordingTurnRequest";

export class RecordingTurnCreator {
  private repository: RecordingTurnRepository;

  constructor(repository: RecordingTurnRepository) {
    this.repository = repository;
  }

  async run(request: RecordingTurnRequest): Promise<DomainRecordingTurn> {
    const recordingTurn = DomainRecordingTurn.create(
      request._id,
      request.owner_id,
      request.price,
      request.description,
      request.date,
      request.startTime,
      request.endTime,
      request.starred,
      request.deleted,
      request.status,
      request.payments,
      request.createdAt,
      request.turnTeam,
      request.approved,
      request.advertisers,
      request.expenses
    );

    return await this.repository.save(recordingTurn);
  }
}
