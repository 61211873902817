//import FormActions from "../../../views/forms/layouts/formActions";

let showList = [];

/**
 * Manages the state of the shows List
 * @param {*} state
 * @param {*} action
 */
const shows = (state = showList, action) => {
  switch (action.type) {
    case "ADD_SHOW":
      if (action.message && action.message === "Show agregado con éxito") {
        return [
          ...state,
          {
            id: action.id,
            name: action.name,
            isErrorName: action.isErrorName,
            genre: action.genre,
            genres: action.genres,
            showSlug: action.showSlug,
            subgenres: action.subgenres,
            synopsis: action.synopsis,
            type: action.type,
            advertisers: action.advertisers,
            showShedules: action.showSchedules,
            email: action.email,
            isEnabled: action.isEnabled,
            pg_classification: action.pg_classification,
            init_date: action.init_date,
            producer: action.producer,
            instagram: action.instagram,
            twitter: action.twitter,
            facebook: action.facebook,
            snapchat: action.snapchat,
            seasons: action.seasons,
            host_1: action.host_1,
            host_2: action.host_2,
            host_3: action.host_3,
            host_4: action.host_4,
            banner_link: action.banner_link,
            responsive_banner_link: action.responsive_banner_link,
            mini_banner_link: action.mini_banner_link,
          },
        ];
      } else {
        console.log("No SE AGREGO");
        //console.log(action);
        return [...state];
      }

    case "UPDATE_SHOW":
      return state.map((show) =>
        show.id === action.id ? { ...show, [action.field]: action.value } : show
      );

    case "TOGGLE_ENABLED_SHOW":
      return state.map((show) =>
        show.id === action.id ? { ...show, isEnabled: !show.isEnabled } : show
      );

    case "TOGGLE_ACTIVE_SHOW":
      return state.map((show) =>
        show.id === action.id ? { ...show, isActive: !show.isActive } : show
      );

    case "GET_ALL_SHOWS":
      return action.shows;

    case "DELETE_SHOW":
      return state.map((show) =>
        show.id === action.id ? { ...show, isDeleted: !show.isDeleted } : show
      );
    case "HI":
      return state;

    default:
      return state;
  }
};

export default shows;
