import { combineReducers } from "redux";
import payments from "./payment";
import paymentDetails from "./paymentDetails";
import paymentVisibilityFilter from "./paymentVisibilityFilter";
import paymentSearch from "./paymentSearch";
import editPayment from "./editPayment";

export default combineReducers({
  payments,
  paymentDetails,
  paymentVisibilityFilter,
  paymentSearch,
  editPayment,
});
