import { ToDoRepository } from "../../../core/todos/domain/ToDoRepository";
import { AxiosToDoRepository } from "../../../core/todos/infrastructure/repository/AxiosToDoRepository";
//import { ToDoDetailGetter } from '../../../core/todos/application/use_cases/getToDoDetail/ToDoDetailtGetter'
import { AllToDosGetter } from "../../../core/todos/application/use_cases/getToDos/AllToDosGetter";
import { UserToDosGetter } from "../../../core/todos/application/use_cases/getUserTodos/UserToDosGetter";
import { toastr } from "react-redux-toastr";
import { ToDoCreator } from "../../../core/todos/application/use_cases/createTodo/ToDoCreator";
import { TodoModifier } from "../../../core/todos/application/use_cases/modifyTodo/TodoModifier";
import { TodoDestroyer } from "../../../core/todos/application/use_cases/deletetodo/TodoDestroyer";

const toDoRepository: ToDoRepository = new AxiosToDoRepository();
const indexToDos = new AllToDosGetter(toDoRepository);
const createTodo = new ToDoCreator(toDoRepository);
const todoModifier = new TodoModifier(toDoRepository);
const todoDestroyer = new TodoDestroyer(toDoRepository);
const userToDosGetter = new UserToDosGetter(toDoRepository);

export const addTodo = (
  _id: string,
  full_name: string,
  task: string,
  owner_id: string,
  start_date: Date,
  due_date: Date,
  project: string,
  priority: boolean,
  tags: string[],
  comments: string,
  image: string,
  status?: string
) => {
  let newTodo = {
    _id: undefined,
    toDo_id: undefined,
    full_name: full_name ? full_name : "",
    task: task ? task : "",
    owner_id: owner_id ? owner_id : "",
    start_date: start_date,
    due_date: due_date,
    project: project ? project : "",
    priority: priority ? priority : false,
    tags: tags ? tags : [],
    images: [],
    department: project,
    comments: comments ? comments : "",
    starred: false,
    deleted: false,
    updatedBy: "",
    slug: task.replace(" ", "-"),
    completed: false,
    image: image,
    status: status ? status : "TODO",
  };

  return (dispatch) => {
    return createTodo
      .run(newTodo)
      .then((res) => {
        toastr.success("Se guardado la tarea");
        dispatch({
          type: "ADD_TODO",
          _id: res._id,
          toDo_id: res.toDo_id,
          full_name: res.full_name,
          task: res.task,
          owner_id: res.owner_id,
          start_date: res.start_date,
          due_date: res.due_date,
          project: res.project,
          priority: res.priority,
          tags: res.tags,
          images: res.images,
          department: res.department,
          comments: res.comments,
          starred: res.starred,
          deleted: res.deleted,
          updatedBy: res.updatedBy,
          slug: res.slug,
          completed: res.completed,
          image: res.image,
          status: res.status,
        });
      })
      .catch((error) => {
        toastr.error("Hubo un problema");
        dispatch({
          type: "ADD_TODO",
          _id: _id ? _id : "",
          toDo_id: _id ? _id : "",
          full_name: full_name ? full_name : "",
          task: task ? task : "",
          owner_id: owner_id ? owner_id : "",
          start_date: start_date,
          due_date: due_date,
          project: project ? project : "",
          priority: priority ? priority : false,
          tags: tags ? tags : [],
          images: [],
          department: project,
          comments: comments ? comments : "",
          starred: false,
          deleted: false,
          updatedBy: "",
          slug: task.replace(" ", "-"),
          completed: false,
          image:
            "https://randomuser.me/api/portraits/thumb/men/" + _id + ".jpg",
          status: status ? status : "TODO",
        });
      });
  };
};

export const updateTodo = (_id, field, value, selectedTodo) => {
  const updatedTodo = {
    _id: _id,
    toDo_id: field === "toDo_id" ? value : selectedTodo.toDo_id,
    full_name: field === "full_name" ? value : selectedTodo.full_name,
    task: field === "task" ? value : selectedTodo.task,
    owner_id: field === "owner_id" ? value : selectedTodo.owner_id,
    start_date: field === "start_date" ? value : selectedTodo.start_date,
    due_date: field === "due_date" ? value : selectedTodo.due_date,
    project: field === "project" ? value : selectedTodo.project,
    priority: field === "priority" ? value : selectedTodo.priority,
    tags: field === "tags" ? value : selectedTodo.tags,
    images: field === "images" ? value : selectedTodo.images,
    department: field === "project" ? value : selectedTodo.project,
    comments: field === "comments" ? value : selectedTodo.comments,
    starred: field === "starred" ? value : selectedTodo.starred,
    deleted: field === "deleted" ? value : selectedTodo.deleted,
    updatedBy: field === "updatedBy" ? value : selectedTodo.updatedBy,
    slug: field === "slug" ? value : selectedTodo.slug,
    completed:
      field === "status" && value === "COMPLETED"
        ? true
        : field === "completed"
        ? value
        : selectedTodo.completed,
    image: field === "image" ? value : selectedTodo.image,
    status: field === "status" ? value : selectedTodo.status,
  };

  return (dispatch) => {
    return todoModifier
      .run(updatedTodo)
      .then((res) => {
        console.log(res);
        toastr.success("Tarea Actualizada");
        dispatch({
          type: "UPDATE_TODO",
          _id: _id,
          field: field,
          value: value,
        });
      })
      .catch((err) => {
        toastr.error("No se puedo actualizar la tarea");
        console.log(err);
      });
  };
};

export const todoDetails = (_id) => {
  return {
    type: "TODO_DETAILS",
    _id,
  };
};

export const assignProject = (_id, label) => ({
  type: "ASSIGN_TODO_PROJECT",
  _id,
  label,
});

export const assignTodoTag = (_id, tag) => ({
  type: "ASSIGN_TODO_TAG",
  _id,
  tag,
});

export const setEditTodoFlag = (flag) => ({
  type: "EDIT_TODO",
  flag,
});

export const setTodoVisibilityFilter = (filter) => ({
  type: "SET_TODO_VISIBILITY_FILTER",
  filter,
});

export const todoSearch = (searchTerm) => ({
  type: "FILTER_TODO",
  payload: searchTerm,
});

export const toggleTodo = (_id) => ({
  type: "TOGGLE_TODO",
  _id,
});

export const toggleStarredTodo = (_id) => ({
  type: "TOGGLE_STARRED_TODO",
  _id,
});

export const toggleCompleteTodo = (_id) => ({
  type: "TOGGLE_COMPLETED_TODO",
  _id,
});

export const togglePriorityTodo = (_id) => ({
  type: "TOGGLE_PRIORITY_TODO",
  _id,
});

export const deleteTodo = (_id) => ({
  type: "DELETE_TODO",
  _id,
});

export const destroyTodo = (_id) => {
  return function (dispatch) {
    return todoDestroyer
      .run(_id)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "DELETE_TODO",
          _id,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "DELETE_TODO",
          _id,
        });
      });
  };
};

export function fetchTodos() {
  return function (dispatch) {
    return indexToDos
      .run()
      .then((data) => {
        console.log(data);
        dispatch({
          type: "GET_ALL_TODOS",
          todoList: data,
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        let errorMessage = "No se pudo obtener la lista de tareas";
        if (err.data) {
          console.log("HAY ERROR DATA");
          console.log(err);
          errorMessage = err.status + " " + err.statusText;
        } else {
          if (err.message) {
            console.log("HAY ERROR MESSAGE");
            errorMessage = err.message;
          } else {
            console.log("ERROR GENERICO INDEX");
          }
        }
        toastr.error(errorMessage);
        dispatch({
          type: "GET_ALL_TODOS",
          todoList: [],
        });
      });
  };
}

export function fetchUserTodos(owner: string) {
  return function (dispatch) {
    return userToDosGetter
      .run(owner)
      .then((data) => {
        console.log(data);
        dispatch({
          type: "GET_USER_TODOS",
          userToDos: data,
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        let errorMessage = "No se pudo obtener la lista de tareas";
        if (err.data) {
          console.log("HAY ERROR DATA");
          console.log(err);
          errorMessage = err.status + " " + err.statusText;
        } else {
          if (err.message) {
            console.log("HAY ERROR MESSAGE");
            errorMessage = err.message;
          } else {
            console.log("ERROR GENERICO INDEX");
          }
        }
        toastr.error(errorMessage);
        dispatch({
          type: "GET_USER_TODOS",
          userToDos: [],
        });
      });
  };
}

export const todoVisiblityFilter = {
  SHOW_ALL: "SHOW_ALL",
  OPEN_TODO: "OPEN_TODO",
  POST_PRODUCTION_TODO: "POST_PRODUCTION_TODO",
  COMPLETED_TODO: "COMPLETED_TODO",
  TECHNICAL_SUPPORT_TODO: "TECHNICAL_SUPPORT_TODO",
  STARRED_TODO: "STARRED_TODO",
  PRIORITY_TODO: "PRIORITY_TODO",
  PROD_TODO: "PROD_TODO",
  ADMIN_TODO: "ADMIN_TODO",
  SALES_TODO: "SALES_TODO",
  PROM_TODO: "PROM_TODO",
  EVENTS_TODO: "EVENTS_TODO",
  OPE_TODO: "OPE_TODO",
  UIUX_TODO: "UIUX_TODO",
  COLLECTIONS_TODO: "COLLECTIONS_TODO",
  FRONTEND_TODO: "FRONTEND_TODO",
  BACKEND_TODO: "BACKEND_TODO",
  SEARCH_TODO: "SEARCH_TODO",
  DELETED_TODO: "DELETED_TODO",
  IN_PROGRESS_TODO: "IN_PROGRESS_TODO",
  FOR_REVIEW_TODO: "FOR_REVIEW_TODOO",
  TODO_TODO: "TODO_TODO",
};
