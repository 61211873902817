import DomainRecordingTurn from "../../domain/RecordingTurn";
import { RecordingTurnRepository } from "../../domain/RecordingTurnRepository";

export class AllRecordingTurnsGetter {
  private repository: RecordingTurnRepository;

  constructor(repository: RecordingTurnRepository) {
    this.repository = repository;
  }

  async run(): Promise<DomainRecordingTurn[]> {
    return await this.repository.index();
  }
}
