import RecordingTurn from "../../domain/RecordingTurn";
import { RecordingTurnRepository } from "../../domain/RecordingTurnRepository";

export class RecordingTurnDestroyer {
  private repository: RecordingTurnRepository;

  constructor(repository: RecordingTurnRepository) {
    this.repository = repository;
  }

  async run(id: string): Promise<RecordingTurn> {
    try {
      return await this.repository.destroy(id);
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
