/**
 * Implements Payment repository interface with axios
 * promise based HTTP client.
 */

import Payment from "../../domain/Payment";
import { PaymentRepository } from "../../domain/PaymentRepository";
import axios from "axios";
import Receipt from "../../domain/Receipt";
import { apiUrl } from "../../../../config";

const baseURL = `https://payments.tnoradio.com/api/payments/`;

export class AxiosPaymentRepository implements PaymentRepository {
  public async index(): Promise<Payment[]> {
    try {
      const response = await axios.get(baseURL + "index");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async save(
    payment: Payment,
    files?: [],
    showName?: string
  ): Promise<Payment> {
    const receiptFiles = this.createFormData(payment, files, showName);
    // receiptFiles.append("payment", JSON.stringify(payment));
    try {
      const response = await axios.post(baseURL + "save", receiptFiles, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      //  console.error(error);
      if (error.response) {
        console.error(error.response);
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async update(
    updatedPayment: Payment,
    field?: string,
    files?: File[],
    showName?: string
  ): Promise<Payment | Error> {
    try {
      const formData = this.createFormData(updatedPayment, files, showName);
      const result = await axios.patch(
        baseURL + "update/" + updatedPayment._id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async destroy(id: string): Promise<Payment> {
    try {
      const response = await axios.delete(baseURL + "destroy/" + id);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async uploadImage(
    file,
    _id,
    location,
    name,
    type,
    paymentId,
    imageName
  ): Promise<any> {
    const formData = new FormData();
    formData.set("image", file, imageName);
    formData.set("_id", _id);
    formData.set("location", location);
    formData.set("type", type);
    formData.set("owner", paymentId);
    formData.set("name", name);

    try {
      const response = await axios.post(baseURL + "uploadtodb", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  }
  public async paymentImage(
    file,
    _id,
    location,
    name,
    type,
    paymentId,
    imageName
  ): Promise<any> {
    const formData = new FormData();
    formData.set("image", file, imageName);
    formData.set("_id", _id);
    formData.set("location", location);
    formData.set("type", type);
    formData.set("owner", paymentId);
    formData.set("name", name);

    try {
      const response = await axios.post(baseURL + "upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  public async updateFiles(
    image,
    name,
    type,
    _id,
    location,
    imageId,
    fileId
  ): Promise<any> {
    const formData = new FormData();
    formData.set("image", image, _id + ".jpg");
    formData.set("name", name);
    formData.set("type", type);
    formData.set("_id", _id);
    formData.set("location", location);
    formData.set("owner", imageId);
    formData.set("fileId", fileId);

    try {
      const response = await axios.patch(baseURL + "updateimage", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  public async getPaymentImage(name: string, _id: string) {
    let res;
    try {
      res = await axios.get(baseURL + "imagefromdb/" + name + "/" + _id);
      return res.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getPaymentById(_id: string): Promise<Payment> {
    let res;
    try {
      res = await axios.get(baseURL + "details/" + _id + "/");
      return res.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  async getPaymentsPage(
    pageSize: number,
    page: number
  ): Promise<Payment[] | Error> {
    try {
      const response = await axios.get(
        baseURL + "index/" + pageSize + "/" + page
      );
      //console.log(response);
      return response.data;
    } catch (error) {
      // console.log(error);
      if (error.response) {
        //console.log("ERROR.RESPONSE");
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          // console.log("ERROR OBJECT " + errorObject);
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getFile(file_id: string, file_name: string): Promise<File> {
    try {
      const response = await axios.get(
        baseURL + "receipt/" + file_id + "/" + file_name
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  public async createFile(payment, file, showName): Promise<Receipt> {
    var files = [];
    files.push(file);
    try {
      const receiptFiles = this.createFormData(payment, files, showName);
      const response = await axios.post(baseURL + "savereceipt", receiptFiles, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return Promise.resolve(null);
      }
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  public async deleteFile(fileId: string): Promise<any> {
    try {
      const response = await axios.delete(baseURL + "destroyfile/" + fileId);
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return Promise.resolve(null);
      }
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  createFormData(payment: Payment, files?: any[], showName?: string) {
    var formData = new FormData();
    //NO FILE DATA SHALL GO FIRST
    formData.append("payment", JSON.stringify(payment));
    showName && formData.append("showName", JSON.stringify(showName || ""));

    files &&
      files.length > 0 &&
      files.forEach((file, index) => {
        const filename =
          payment.owner_id +
          "_" +
          index +
          "_" +
          payment.payment_date +
          file.name.substring(file.name.lastIndexOf("."), file.name.length);
        formData.append("receipts", file, filename);
      });
    return formData;
  }

  async fetchFile(fileUrl: string): Promise<any> {
    try {
      const res = await axios({
        method: "get",
        url: fileUrl,
        responseType: "stream",
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }
}
