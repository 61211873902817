import Campaign from "../../domain/Campaign";
import { CampaignRepository } from "../../domain/CampaignRepository";

export class AllCampaignsGetter {
  private repository: CampaignRepository;

  constructor(repository: CampaignRepository) {
    this.repository = repository;
  }

  async run(): Promise<Campaign[]> {
    return await this.repository.index();
  }
}
