let initState = null;

const buyerPerson = (state = initState, action) => {
  switch (action.type) {
    case "ADD_BUYER_PERSON":
      return {
        _id: action._id,
        age_range: action.age_range,
        genders: action.genders,
        interest_topics: action.interest_topics,
        target: action.target,
        geolocalization: action.geolocalization,
        brand_type: action.brand_type,
      };

    case "UPDATE_BUYER_PERSON":
      return state.map((buyerPerson) =>
        buyerPerson._id === action._id
          ? { ...buyerPerson, [action.field]: action.value }
          : buyerPerson
      );

    case "TOGGLE_ACTIVATE_BUYER_PERSON":
      return state.map((buyerPerson) =>
        buyerPerson._id === action._id
          ? { ...buyerPerson, isActive: !buyerPerson.isActive }
          : buyerPerson
      );

    case "TOGGLE_STARRED_BUYER_PERSON":
      return state.map((buyerPerson) =>
        buyerPerson._id === action._id
          ? { ...buyerPerson, starred: !buyerPerson.starred }
          : buyerPerson
      );

    case "DESTROY_BUYER_PERSON":
      return state.map((buyerPerson) =>
        buyerPerson._id === action._id
          ? { ...buyerPerson, deleted: !buyerPerson.deleted }
          : buyerPerson
      );

    case "GET_ALL_BUYER_PERSONS":
      return action.buyerPerson;

    case "GET_USER_BUYER_PERSONS":
      return action.buyerPerson;

    default:
      return state;
  }
};

export default buyerPerson;
