/**
 * Implements Client repository interface with axios
 * promise based HTTP client.
 */

import Client from "../../domain/Client";
import { ClientRepository } from "../../domain/ClientRepository";
import axios from "axios";
import { apiUrl } from "../../../../config";
import Agency from "../../domain/Agency";

const baseURL = `${apiUrl}ml`;

export class AxiosClientRepository implements ClientRepository {
  async getAllAgencies(): Promise<Error | Agency[]> {
    try {
      const response = await axios.get(baseURL + "getAgencies");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async index(): Promise<Client[]> {
    try {
      const response = await axios.get(baseURL + "/get-classify-clients");
      return response.data.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async save(client: Client): Promise<Client> {
    // receiptFiles.append("payment", JSON.stringify(payment));
    try {
      const response = await axios.post(baseURL + "save", client);
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      //  console.error(error);
      if (error.response) {
        console.error(error.response);
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async update(
    id: string,
    field: string,
    value: any
  ): Promise<Client | Error> {
    try {
      const updateClient = { id: id, value: value, field: field };
      const result = await axios.patch(baseURL + "update/" + id, updateClient);
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async updateIsUserStatus(id: string): Promise<Client | Error> {
    try {
      console.log(baseURL + "update-potential-customer/" + id);
      const result = await axios.get(
        baseURL + "update-potential-customer/" + id
      );
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async destroy(id: string): Promise<Client> {
    try {
      const response = await axios.delete(baseURL + "destroy/" + id);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async uploadImage(
    file,
    _id,
    location,
    name,
    type,
    paymentId,
    imageName
  ): Promise<any> {
    const formData = new FormData();
    formData.set("image", file, imageName);
    formData.set("_id", _id);
    formData.set("location", location);
    formData.set("type", type);
    formData.set("owner", paymentId);
    formData.set("name", name);

    try {
      const response = await axios.post(baseURL + "uploadtodb", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  }
  public async paymentImage(
    file,
    _id,
    location,
    name,
    type,
    paymentId,
    imageName
  ): Promise<any> {
    const formData = new FormData();
    formData.set("image", file, imageName);
    formData.set("_id", _id);
    formData.set("location", location);
    formData.set("type", type);
    formData.set("owner", paymentId);
    formData.set("name", name);

    try {
      const response = await axios.post(baseURL + "upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  public async updateFiles(
    image,
    name,
    type,
    _id,
    location,
    imageId,
    fileId
  ): Promise<any> {
    const formData = new FormData();
    formData.set("image", image, _id + ".jpg");
    formData.set("name", name);
    formData.set("type", type);
    formData.set("_id", _id);
    formData.set("location", location);
    formData.set("owner", imageId);
    formData.set("fileId", fileId);

    try {
      const response = await axios.patch(baseURL + "updateimage", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  public async getClientImage(name: string, _id: string) {
    let res;
    try {
      res = await axios.get(baseURL + "imagefromdb/" + name + "/" + _id);
      return res.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getClientById(_id: string): Promise<Client> {
    let res;
    try {
      res = await axios.get(baseURL + "details/" + _id + "/");
      return res.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  async getClientsPage(
    pageSize: number,
    page: number
  ): Promise<Client[] | Error> {
    try {
      const response = await axios.get(
        baseURL + "index/" + pageSize + "/" + page
      );
      //console.log(response);
      return response.data;
    } catch (error) {
      // console.log(error);
      if (error.response) {
        //console.log("ERROR.RESPONSE");
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          // console.log("ERROR OBJECT " + errorObject);
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getFile(file_id: string, file_name: string): Promise<File> {
    try {
      const response = await axios.get(
        baseURL + "receipt/" + file_id + "/" + file_name
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async fetchFile(fileUrl: string): Promise<any> {
    try {
      const res = await axios({
        method: "get",
        url: fileUrl,
        responseType: "stream",
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }
}
