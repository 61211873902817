import { ToDoRepository } from "../../../domain/ToDoRepository";
//import { DeleteUserRequest } from './DeleteUserRequest'
import { ToDo } from "../../../domain/ToDo";

export class TodoDestroyer {
  private repository: ToDoRepository;

  constructor(repository: ToDoRepository) {
    this.repository = repository;
  }

  async run(_id: string): Promise<ToDo | Error> {
    //ALGO EXTRAÑO SUCEDE, EL REQUEST RETORNA DIRECTO EL ID SIN
    //ENTRAR AL CAMPO
    try {
      console.log(_id);

      return await this.repository.destroyToDo(_id);
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
