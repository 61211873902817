const PostDetails = (state = "", action) => {
  //console.log(action);
  switch (action.type) {
    case "POST_DETAILS":
      return action._id;
    default:
      return state;
  }
};

export default PostDetails;
