import EmployeeTurn from "../../../domain/EmployeeTurn";
import { UserRepository } from "../../../domain/UserRepository";

export class EmployeeTurnCreator {
  private repository: UserRepository;

  constructor(repository: UserRepository) {
    this.repository = repository;
  }

  async run(request: EmployeeTurn): Promise<EmployeeTurn> {
    const employeeTurn = EmployeeTurn.create(
      request._id,
      request.startTime,
      request.endTime,
      request.owner,
      request.date,
      request.activities
    );

    return await this.repository.saveEmployeeTurn(employeeTurn);
  }
}
