const EditRecordingTurn = (state = false, action) => {
  switch (action.type) {
    case "EDIT_RECORDING_TURN":
      return !state ? true : false;
    case "RECORDING_TURN_DETAILS":
      return (state = false);
    default:
      return state;
  }
};

export default EditRecordingTurn;
