const initialState = {
  published_instagram: [],
};

const GetAllPublishedInstagramReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PUBLISHED_INSTAGRAM":
      return {
        published_instagram: action.published_instagram,
      };
    default:
      return state;
  }
};

export default GetAllPublishedInstagramReducer;
