import Expense from "../../../domain/Expense";
import { ExpenseRequest } from "../../ExpenseRequest";
import { ExpenseRepository } from "../../../domain/ExpenseRepository";

export class ExpenseCreator {
  private repository: ExpenseRepository;

  constructor(repository: ExpenseRepository) {
    this.repository = repository;
  }

  async run(request: ExpenseRequest, files: []): Promise<Expense> {
    const expense = Expense.create(
      request._id,
      request.amountBs,
      request.amountUsd,
      request.amountEuro,
      request.amountCripto,
      request.amountOther,
      request.owner_id,
      request.recording_turn_owner,
      request.concept,
      request.receipts,
      request.date,
      request.starred,
      request.deleted,
      request.reconciled,
      request.currency,
      request.method,
      request.expense_type
    );

    return await this.repository.save(expense, files);
  }
}
