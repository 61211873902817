import { paymentVisibilityFilter } from "../../actions/payments";

const PaymentVisibilityFilter = (
  state = paymentVisibilityFilter.SHOW_ALL,
  action
) => {
  switch (action.type) {
    case "SET_VISIBILITY_FILTER":
      return action.filter;
    default:
      return state;
  }
};

export default PaymentVisibilityFilter;
