const initialState = [];

const showTypes = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SHOW_TYPES":
      return [...action.showTypes];
    default:
      return state;
  }
};

export default showTypes;
