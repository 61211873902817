import { User } from "./User";

export class UserSession {
  readonly user: User;
  readonly token: string;

  constructor(user: User, token: string) {
    this.user = user;
    this.token = token;
  }

  createSession(user: User, token: string) {
    return new UserSession(user, token);
  }
}
