import Agency from "../../../domain/Agency";
import { ClientRepository } from "../../../domain/ClientRepository";

export class GetAgency {
  private repository: ClientRepository;

  constructor(repository: ClientRepository) {
    this.repository = repository;
  }

  async run(): Promise<Agency[] | Error> {
    return await this.repository.getAllAgencies();
  }
}
