const showSeasonsWithEpisodes = (state = [], action) => {
  switch (action.type) {
    case "FETCH_SHOW_SEASONS_WITH_EPISODES":
      return action.showSeasonsWithEpisodes;

    default:
      return state;
  }
};

export default showSeasonsWithEpisodes;
