const CampaignDetails = (state = "", action) => {
  switch (action.type) {
    case "CAMPAIGN_DETAILS":
      return action._id;
    default:
      return state;
  }
};

export default CampaignDetails;
