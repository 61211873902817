import { combineReducers } from "redux";
import users from "./users";
import userDetails from "./userDetails";
import usersVisibilityFilter from "./usersVisibilityFilter";
import usersSearch from "./usersSearch";
import editUser from "./editUser";
import fetchUsers from "./fetchUsers";
import fetchUserImage from "./fetchUserImage";
import updateUserImage from "./updateUserImage";

export default combineReducers({
  users,
  userDetails,
  usersVisibilityFilter,
  usersSearch,
  editUser,
  fetchUsers,
  fetchUserImage,
  updateUserImage,
});
